import React from 'react'

import SideBar from '@components/SideBar/SideBar'
import { CandidatesListInterface } from '@src/interfaces/interviewTool'
import { CVInsightsWidget } from '@src/features/CVInsightsWidget/CVInsightsWidget'

interface CVScreeningSidebarProps {
  open: boolean
  onClose: VoidFunction
  candidate?: CandidatesListInterface
}

export const CVScreeningSidebar = ({
  open,
  onClose,
  candidate,
}: CVScreeningSidebarProps) => {
  if (!candidate || !candidate.active_interview_round) {
    return null
  }

  return (
    <SideBar
      isOpen={open}
      onClose={onClose}
      title={candidate.full_name}
      subtitle={candidate.active_interview_round.specialisation.name}
    >
      <CVInsightsWidget
        roundId={candidate.active_interview_round.id}
        specialisationId={candidate.active_interview_round.specialisation.id}
      />
    </SideBar>
  )
}
