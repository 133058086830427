import React from 'react'
import { Color, Token, Tooltip, useTooltip } from '@revolut/ui-kit'

import {
  EngagementResultInterface,
  EngagementResultsAverageScoreInterface,
} from '@src/features/Engagement/api/analytics/interfaces'
import { PreviewBar } from './PreviewBar'
import { Summary } from './RatingTooltipContent/Summary'

type Distribution = {
  color: Color
  width: number
}

const getAvgDistribution = ({
  averageScore,
}: {
  averageScore: EngagementResultsAverageScoreInterface
}): Distribution[] => {
  const total = averageScore.respondents || 1

  return [
    {
      color: Token.color.danger,
      width: averageScore.detractors / total,
    },
    {
      color: Token.color.yellow,
      width: averageScore.passives / total,
    },
    {
      color: Token.color.success,
      width: averageScore.promoters / total,
    },
    {
      color: Token.color.greyTone20,
      width: averageScore.ambivalents / total,
    },
  ]
}

interface Props {
  data: EngagementResultInterface
}
export const DistributionChart = ({ data }: Props) => {
  const tooltip = useTooltip()

  const averageScore = {
    ambivalents: data.ambivalents,
    detractors: data.detractors,
    passives: data.passives,
    promoters: data.promoters,
    respondents: data.respondents,
  }
  const avgDistribution = getAvgDistribution({ averageScore })

  if (data.type === 'Open ended') {
    return <>-</>
  }
  return (
    <>
      <PreviewBar tooltip={tooltip} distribution={avgDistribution} />
      <Tooltip {...tooltip.getTargetProps()}>
        <Summary averageScore={averageScore} />
      </Tooltip>
    </>
  )
}
