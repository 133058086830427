import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { pathToUrl } from '@src/utils/router'
import { selectorKeys } from '@src/constants/api'
import { dashboardTypeRouteMap } from '@src/pages/Forms/DataAnalyticsDashboardForm/constants'
import Table from '@src/components/TableV2/Table'
import { DASHBOARD_DEFAULT_ICON } from '@src/constants/common'
import {
  Action,
  Avatar,
  HStack,
  Icon,
  IconButton,
  Link,
  Tag,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { DepartmentTabSectionInterface } from '../interfaces'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getValueTypeIcon, navigateToDepartmentResourcePath } from '../helpers'

export const departmentResourcesAnalyticsDashboardNameColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.dashboard_names,
    dynamicHyperlinks: data =>
      pathToUrl(dashboardTypeRouteMap[data.dashboard_type], { id: data.id }),
    title: 'Name',
    insert: ({ data, url }) => (
      <Table.EntityCell
        useIcon={data.icon || DASHBOARD_DEFAULT_ICON}
        avatar={data.avatar}
        url={url}
      >
        {data.name}
      </Table.EntityCell>
    ),
  }

export const departmentResourcesAnalyticsDashboardOwnerColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.dashboard_owner_employees,
    title: 'Owner',
    insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
  }

export const departmentResourcesAnalyticsDashboardDescriptionColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.text,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
  }

export const departmentResourcesAnalyticsDashboardSourceColumn: ColumnInterface<GenericAnalyticsDashboardInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'dashboard_type',
    dataPoint: 'dashboard_type',
    sortKey: null,
    filterKey: 'dashboard_type',
    selectorsKey: selectorKeys.dashboard_types,
    title: 'Source',
    insert: ({ data }) => {
      if (data.dashboard_type === 'looker') {
        return (
          <Icon src="https://assets.revolut.com/assets/apps/Looker.png" variant="image" />
        )
      }

      if (data.dashboard_type === 'tableau') {
        return (
          <Icon
            src="https://assets.revolut.com/assets/apps/Tableau.webp"
            variant="image"
          />
        )
      }

      return <Icon name="LogoRevolut" />
    },
  }

interface DepartmentResourcesCommonColumnProps {
  departmentId: number
  tabId: number
  canManageResources: boolean
}

export const departmentResourcesResourceNameColumn = ({
  departmentId,
  tabId,
  canManageResources,
}: DepartmentResourcesCommonColumnProps): ColumnInterface<DepartmentTabSectionInterface> => ({
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => {
    return (
      <HStack space="s-8" align="center">
        <Avatar useIcon={<Icon src={getValueTypeIcon(data.value_type)} />} size={36} />
        {data.value ? (
          <Link href={data.value} target="_blank">
            <HStack space="s-8" align="center">
              <Text variant="body1" color={Token.color.foreground} overflow="hidden">
                {data.name}
              </Text>
              <Icon name="LinkExternal" size="s-16" />
            </HStack>
          </Link>
        ) : (
          <VStack>
            <HStack space="s-8" align="center">
              <Text variant="body1">{data.name}</Text>
              <Icon
                name="ExclamationMarkOutline"
                size="s-16"
                color={Token.color.orange}
                title={
                  canManageResources
                    ? `The resource is missing a link.`
                    : `The resource is missing a link, and you lack permission to add it.`
                }
              />
            </HStack>
            {canManageResources && (
              <Action
                onClick={() =>
                  navigateToDepartmentResourcePath({
                    departmentId,
                    tabId,
                    id: data.id,
                  })
                }
              >
                <Icon name="Plus" size={12} />
                Add link
              </Action>
            )}
          </VStack>
        )}
      </HStack>
    )
  },
})

export const departmentResourcesResourceTypeColumn: ColumnInterface<DepartmentTabSectionInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
    insert: ({ data }) => {
      if (data.value_type) {
        return (
          <Tag
            useIcon={<Icon src={getValueTypeIcon(data.value_type)} size={14} />}
            variant="faded"
          >
            {data.value_type.name}
          </Tag>
        )
      }

      return 'n/a'
    },
  }

export const departmentResourcesResourceOwnerColumn: ColumnInterface<DepartmentTabSectionInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.full_name',
    sortKey: 'owner__full_name',
    filterKey: 'owner__id',
    selectorsKey: selectorKeys.employee,
    title: 'Owner',
    insert: ({ data }) => <UserWithAvatar {...data.owner} />,
  }

export const departmentResourcesResourceDescriptionColumn: ColumnInterface<DepartmentTabSectionInterface> =
  {
    type: CellTypes.text,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
  }

export const departmentResourcesResourceActionColumn = ({
  departmentId,
  tabId,
}: DepartmentResourcesCommonColumnProps): ColumnInterface<DepartmentTabSectionInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <IconButton
        useIcon="Pencil"
        onClick={() =>
          navigateToDepartmentResourcePath({
            departmentId,
            tabId,
            id: data.id,
          })
        }
        size={18}
      />
    )
  },
})
