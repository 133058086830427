import React, { Ref } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  deliverablesRating,
  CommonGradeOption,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformanceLayout/components/HelpSections/CombinedHelp'
import { ProbationDeliverables } from '@src/pages/Forms/ProbationLayout/Sections/ProbationDeliverables'
import { getViewGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { Box, VStack, Widget } from '@revolut/ui-kit'
import { CardHeader } from '../../EmployeePerformanceLayout/CardHeader'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'

interface ProbationGoalsCardProps extends CommonCardProps {
  headerRef?: Ref<HTMLDivElement>
}

// FYI It's called "Probation" but in reality it's also used in PIP  https://revolut.atlassian.net/browse/REVC-8586
export const ProbationGoalsCard = connect(
  ({ onHelpClick, gradesMap, headerRef }: ProbationGoalsCardProps) => {
    const { values } = useLapeContext<ReviewScorecardInterface>()

    const onSelectGrade = (grade: CommonGradeOption) => {
      set(
        values,
        `review_data.calculated_deliverables_ratings.recommended_rating`,
        grade.key,
      )
    }

    return (
      <Widget p="s-16">
        <CardHeader
          data={values}
          title="Goals"
          icon="Target"
          onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
        />
        <VStack ref={headerRef} space="s-16">
          <Box mt="s-16">
            <ProbationDeliverables
              reviewData={values.review_data}
              category={values.category}
              team={values.team}
              cycleId={values.performance_checkpoint?.cycle_id}
              checkpointNum={values.probation_checkpoint?.number}
              gradesMap={gradesMap}
            />
          </Box>

          <Card
            variant="content"
            data={values}
            renderExpandedContent={() => (
              <Box mt="s-16">
                <LapeNewTextArea
                  rows={2}
                  label="Justification and comments"
                  name="review_data.calculated_deliverables_ratings.recommended_rating_comment"
                />
              </Box>
            )}
            type={CardContentTypes.DELIVERABLES}
            fields={[
              {
                field: 'review_data.calculated_deliverables_ratings',
                title: 'Goal performance',
                grades: getViewGradesWithExpectations(deliverablesRating),
                cardJustification: null,
              },
            ]}
            onSelectDeliverableGrade={onSelectGrade}
            isGradeSelectedRule={(field, grade) => {
              const ratingValue = get(values, field)?.recommended_rating
              return !!ratingValue && ratingValue === grade.key
            }}
          />
        </VStack>
      </Widget>
    )
  },
)
