import { HStack, Icon, Text, VStack, Widget } from '@revolut/ui-kit'
import { getCategoryIconName, getCategoryName, InsightScoreVariant } from '../helpers'
import { InsightsItem } from '../InsightsItem'
import { InsightsPlaceholder } from './InsightsPlaceholder'
import React from 'react'
import { InsightWithScore } from '../hooks/useGetInsightsWithScore'
import {
  EngagementInsightCategory,
  EngagementInsightsTarget,
} from '@src/interfaces/engagement'

interface Props {
  insights?: InsightWithScore[]
  scoreVariant: InsightScoreVariant
  onInsightClick: (insight: InsightWithScore) => void
  insightType?: EngagementInsightsTarget
  category: EngagementInsightCategory
}

export const InsightsCategory = ({
  insights,
  category,
  scoreVariant,
  onInsightClick,
  insightType,
}: Props) => {
  return (
    <Widget p="s-16" width="100%">
      <HStack gap="s-12" align="center">
        <Icon name={getCategoryIconName(category)} />
        <Text variant="heading3">{getCategoryName(category)}</Text>
      </HStack>
      <VStack gap="s-12" mt="s-12">
        {insights?.length ? (
          insights.map((insight, i) => (
            <HStack gap="s-8" key={i}>
              <Icon name="Dot" size={12} mt="s-4" />
              <InsightsItem
                insight={insight}
                scoreVariant={scoreVariant}
                titleIconName={
                  insightType === 'survey' ? 'LinkExternalSimple' : undefined
                }
                onClick={onInsightClick}
                insightType={insightType}
              />
            </HStack>
          ))
        ) : (
          <InsightsPlaceholder status="no_insights" />
        )}
      </VStack>
    </Widget>
  )
}
