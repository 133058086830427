import React from 'react'
import { ProbationResults } from '@src/interfaces/probationReview'
import { probationResultToString } from '@src/constants/performance'
import { Text, Token } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'

type Props = {
  result?: ProbationResults
  status: Statuses
  className?: string
}

const ProbationResult = ({ result, status, className }: Props) => {
  const isPending = status === Statuses.pending
  let color: string = Token.color.foreground

  switch (result) {
    case ProbationResults.Passed:
      color = Token.color.green
      break
    case ProbationResults.Failed:
      color = Token.color.red
      break
  }

  if (isPending) {
    color = Token.color.greyTone50
  }

  return (
    <Text className={className} color={color} variant="body1" textAlign="right">
      {isPending ? 'Pending' : probationResultToString(result)}
    </Text>
  )
}

export default ProbationResult
