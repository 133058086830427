import { Avatar, HStack, Text } from '@revolut/ui-kit'
import React, { useEffect, useRef } from 'react'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
  InterviewRoundInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import { format } from 'date-fns'
import { connect } from 'lape'
import SideBar from '@components/SideBar/SideBar'
import { getAvatarUrl } from '@src/utils/employees'
import { InterviewFeedback } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedback'
import { InterviewerProfileTooltip } from '@src/features/InterviewerProfile/InterviewerProfileTooltip'
import { FormattedMessage } from 'react-intl'

type Props = {
  isOpen: boolean
  onExit: () => void
  scorecard?: InterviewFeedbackInterface
  round?: InterviewRoundInterface
  title?: string
  stageType?: InterviewType
}

export const InterviewFeedbackSidebar = connect(
  ({ isOpen, onExit, scorecard, round, title, stageType }: Props) => {
    const scrollRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      scrollRef.current?.scrollIntoView({ block: 'center' })
    }, [scorecard?.id])

    if (!isOpen) {
      return null
    }

    return (
      <SideBar
        title={title}
        variant="wide"
        onClose={onExit}
        subtitle={
          scorecard?.interviewer ? (
            <InterviewerProfileTooltip interviewerId={scorecard.interviewer.id}>
              <HStack gap="s-8">
                <Avatar
                  style={{ flexShrink: 0 }}
                  size={24}
                  image={getAvatarUrl(scorecard?.interviewer?.avatar)}
                >
                  {!scorecard?.interviewer?.avatar ? (
                    <Text>{scorecard?.interviewer?.full_name?.[0]}</Text>
                  ) : undefined}
                </Avatar>
                <Text>
                  {scorecard?.status === InterviewFeedbackStatus.completed ? (
                    <FormattedMessage
                      id="recruitment.scorecard.interviewer.submittedByMessage"
                      defaultMessage="Submitted by {interviewer} on {date}"
                      values={{
                        interviewer: scorecard?.interviewer?.full_name,
                        date: format(
                          new Date(scorecard?.updated_date_time),
                          'd MMM yyyy, hh:mm a',
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      id="recruitment.scorecard.interviewer.pending"
                      defaultMessage="Pending with "
                      values={{ interviewer: scorecard?.interviewer?.full_name }}
                    />
                  )}
                </Text>
              </HStack>
            </InterviewerProfileTooltip>
          ) : undefined
        }
      >
        <InterviewFeedback
          ref={scrollRef}
          round={round}
          scorecard={scorecard}
          stageType={stageType}
        />
      </SideBar>
    )
  },
)
