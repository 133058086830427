import React, { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Caption,
  Group,
  Header,
  Item,
  Popup,
  StatusPopup,
  Text,
  Token,
  useStatusPopup,
  useToggle,
} from '@revolut/ui-kit'
import { PlanType } from '@src/pages/Forms/Plans/types'
import {
  AvailableSubscriptionPlanInterfaceV2,
  SubscriptionPlanInfterface,
} from '@src/interfaces/plans'
import {
  getMerchantApiConfig,
  createMerchantApiOrder,
  useRequestManualPlan,
  changeMerchantApiPaymentMethod,
  useSubsciptionInfo,
  switchStandardPlan,
} from '@src/api/plans'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useSubscriptionPlanContextV2 } from '@src/pages/Forms/Plans/SubscriptionPlanProvider'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { apiV2 } from '@src/api'
import { WhoAmIInterface } from '@src/interfaces/auth'
import { API } from '@src/constants/api'
import { useDispatch } from 'react-redux'
import { setFeatureFlagsAction } from '@src/store/auth/actions'
import { isStandardPlan } from './config'

interface SelectPlanButtonProps {
  plan: AvailableSubscriptionPlanInterfaceV2 | SubscriptionPlanInfterface
  currentPlanId: number | undefined
  planType: PlanType
  variant?: ButtonVariant
  noPaymentMethod: boolean
}

export const SelectPlanButton = ({
  plan,
  currentPlanId,
  planType,
  variant,
  noPaymentMethod,
}: SelectPlanButtonProps) => {
  const [openUpgradePopup, toggleOpenUpgradePopup] = useToggle()
  const { refetch: refetchSubscriptionInfo } = useSubsciptionInfo()

  const showStatusPopup = useShowStatusPopup()
  const errorPopup = useErrorPopup()
  const { mutateAsync: requestManualPlan, isLoading: applyForAnnualPlanPending } =
    useRequestManualPlan()
  const [createOrderPending, setCreateOrderPending] = useState(false)
  const { selectedPlan, setSelectedPlan, setOrderAndConfig } =
    useSubscriptionPlanContextV2()
  const statusPopup = useStatusPopup()
  const [isRequestingUpgrade, setIsRequestingUpgrade] = useState(false)
  const isSelectedPlan = currentPlanId && plan.id === currentPlanId
  const dispatch = useDispatch()

  const subscribeToFirstPlan = () => {
    if (!createOrderPending) {
      setCreateOrderPending(true)

      Promise.all([getMerchantApiConfig(), createMerchantApiOrder(plan.id)])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'subscribe',
          })
          // At this point we know it must be the available plan as there is no plan selected yet (we're on the checkout)
          setSelectedPlan(plan as AvailableSubscriptionPlanInterfaceV2)
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }
  const handleUpgradePlan = () => {
    setIsRequestingUpgrade(true)
    switchStandardPlan({ subscription_plan_id: plan.id })
      .then(async () => {
        return refetchSubscriptionInfo()
      })
      .then(async subscriptionInfo => {
        const whoAmI = await apiV2.get<WhoAmIInterface>(
          API.WHOAMI,
          undefined,
          undefined,
          true,
        )

        setIsRequestingUpgrade(false)
        dispatch(setFeatureFlagsAction(whoAmI.data.feature_flags))

        toggleOpenUpgradePopup.off()

        statusPopup.show(
          <StatusPopup variant="success-result" onClose={statusPopup.hide}>
            <StatusPopup.Title>
              {subscriptionInfo.data?.subscription_plan?.name
                ? `You upgraded to ${subscriptionInfo.data?.subscription_plan?.name} plan!`
                : 'You switched to the new plan!'}
            </StatusPopup.Title>
            <StatusPopup.Description>
              Start exploring the new features now.
            </StatusPopup.Description>

            <StatusPopup.Actions>
              <Button
                onClick={() => {
                  statusPopup.hide()
                  navigateTo(ROUTES.MAIN)
                }}
                variant="secondary"
              >
                Continue
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
      .catch(error => {
        setIsRequestingUpgrade(false)
        errorPopup.show({
          error,
          fallbackTitle: 'Failed to upgrade plan',
        })
      })
  }

  const applyForCustomPlan = async () => {
    try {
      await requestManualPlan({
        custom_plan_requested_bundle_features: plan.bundle_features,
      })
      showStatusPopup({
        title: 'We’ll contact you soon',
        description: "We'll be in touch shortly to discuss a plan offer for you",
        status: 'pending',
      })
    } catch (error) {
      showStatusPopup({
        title: 'Apply for annual plan failed',
        description: getMessageFromApiError(error),
        status: 'error',
      })
    }
  }

  const addPaymentMethodAndSwitchPlan = () => {
    if (!createOrderPending) {
      setCreateOrderPending(true)
      Promise.all([getMerchantApiConfig(), changeMerchantApiPaymentMethod()])
        .then(([configResponse, orderResponse]) => {
          setOrderAndConfig({
            order: orderResponse.data,
            config: configResponse.data,
            type: 'change-payment-method',
          })
          navigateTo(ROUTES.PLANS.CHECKOUT)
        })
        .catch(() => {
          setCreateOrderPending(false)
        })
    }
  }

  const handleButtonClick = (): void => {
    if (planType === 'monthly' && noPaymentMethod) {
      if (!currentPlanId) {
        subscribeToFirstPlan()
      } else {
        addPaymentMethodAndSwitchPlan()
      }
      return
    }
    if (planType === 'custom') {
      applyForCustomPlan()
      return
    }

    toggleOpenUpgradePopup.on()
  }
  const isSelectedMonthlyPlan = isSelectedPlan && planType === 'monthly'

  const getButtonText = () => {
    if (isSelectedMonthlyPlan) {
      return 'Your current plan'
    }
    if (planType === 'custom' && !isStandardPlan(plan)) {
      // It's custom one for sure. Our "Annual" standard plan is also technically custom so with these two checks we're sure it's custom
      return 'Request changes'
    }
    if (planType !== 'monthly') {
      // This is our "Annual" plan tab display
      return 'Contact us'
    }
    if (!selectedPlan) {
      return 'Get started'
    }
    if (Number(selectedPlan.cycle_fee) >= Number(plan.cycle_fee)) {
      return 'Switch'
    }
    return 'Upgrade'
  }

  const isUpgradingPlan =
    !!selectedPlan && Number(selectedPlan.cycle_fee) < Number(plan.cycle_fee)
  const isSwitchingPlan =
    !!selectedPlan && Number(selectedPlan.cycle_fee) === Number(plan.cycle_fee)
  const isDowngradingPlan =
    !!selectedPlan && Number(selectedPlan.cycle_fee) > Number(plan.cycle_fee)

  return (
    <>
      <Button
        useIcon={isSelectedMonthlyPlan ? 'CheckSuccess' : undefined}
        variant={variant || isSelectedMonthlyPlan ? 'primary' : 'secondary'}
        onClick={handleButtonClick}
        pending={applyForAnnualPlanPending || createOrderPending}
      >
        {getButtonText()}
      </Button>
      <Popup
        variant="modal-view"
        open={openUpgradePopup}
        onClose={toggleOpenUpgradePopup.off}
      >
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Confirm plan change</Header.Title>
        </Header>
        <Group>
          {(isUpgradingPlan || isSwitchingPlan) && (
            <Item useIcon="LockOpened" iconColor={Token.color.foreground}>
              <Item.Content>
                <Item.Title>
                  <Text variant="h6">New features</Text>
                </Item.Title>
                <Item.Description>
                  You can instantly start using the features available in your new plan
                </Item.Description>
              </Item.Content>
            </Item>
          )}
          {(isUpgradingPlan || isDowngradingPlan) && (
            <Item useIcon="Repayment" iconColor={Token.color.foreground}>
              <Item.Content>
                <Item.Title>
                  <Text variant="h6">Price change</Text>
                </Item.Title>
                <Item.Description>
                  The price change will be applied from your next invoice. Downgrade for
                  free before the end of the billing cycle
                </Item.Description>
              </Item.Content>
            </Item>
          )}
          {(isSwitchingPlan || isDowngradingPlan) && (
            <Item useIcon="ExclamationMark" iconColor={Token.color.foreground}>
              <Item.Content>
                <Item.Title>
                  <Text variant="h6">Lost features</Text>
                </Item.Title>
                <Item.Description>
                  You will keep the access to features of your old plan until the the end
                  of the paid period.
                </Item.Description>
              </Item.Content>
            </Item>
          )}
        </Group>
        <Popup.Actions>
          <Button variant="secondary" onClick={toggleOpenUpgradePopup.off}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUpgradePlan}
            pending={isRequestingUpgrade}
          >
            {isUpgradingPlan ? 'Upgrade' : isSwitchingPlan ? 'Switch' : 'Downgrade'}
          </Button>
          <Caption color={Token.color.greyTone20} textAlign="center" use="div" pb="s-32">
            Final price will be calculated based on the number of active employees at the
            end of the billing cycle. The price excludes tax.
          </Caption>
        </Popup.Actions>
      </Popup>
    </>
  )
}
