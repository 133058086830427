import { useGlobalSettings } from '@src/api/settings'
import { FieldOptions } from '@src/interfaces'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'

export const useCanViewEngagementTab = <
  T extends { id: number; field_options: FieldOptions },
>({
  entity,
  checkLocalPermission,
}: {
  entity: T | undefined
  checkLocalPermission?: boolean
}) => {
  const { settings, isLoading } = useGlobalSettings()
  const isEngagementManager = useIsEngagementManager()

  const hasGlobalTabVisibilityPermission = useHasGlobalPermission(
    PermissionTypes.ViewEngagementTabs,
  )
  const hasLocalTabVisibilityPermission = checkLocalPermission
    ? entity?.field_options.permissions?.includes(PermissionTypes.ViewEngagementTabs)
    : true
  const hasPermissions =
    isEngagementManager ||
    (hasGlobalTabVisibilityPermission && hasLocalTabVisibilityPermission)

  return {
    canView: Boolean(entity?.id && settings.engagement_enabled && hasPermissions),
    isLoading,
  }
}

export const useIsEngagementManager = () => {
  const isEngagementManager = useHasGlobalPermission(
    PermissionTypes.EngagementManagerPermissions,
  )
  return isEngagementManager
}

export const useIsEngagementLayoutV2 = ({
  isOrganisationCompanySubtab,
}: {
  isOrganisationCompanySubtab: boolean | undefined
}) => {
  // TODO: RHR-7276 uncomment when testing finished and ready for internal release
  //
  // const isCommercial = useIsCommercialProduct()
  // const isEngagementV2 = useHasFeatureFlag(FeatureFlags.EngagementV2) // TODO: RHR-6950 remove v1 completely when ready

  // if (isCommercial) {
  //   return isEngagementV2
  // }
  // v2 is a default layout for the internal product except "organisation -> company" subtab (RHR-6949)
  // return !isOrganisationCompanySubtab

  return useHasFeatureFlag(FeatureFlags.DevEngagementV2) && !isOrganisationCompanySubtab
}
