import { PerformanceSelector } from '@src/interfaces/performance'
import React, { useState } from 'react'
import { ActionButton, Box, Button, Icon, Side, Token } from '@revolut/ui-kit'
import { CollapsableTableWrapper } from '@src/pages/Forms/EmployeePerformanceLayout/components/Summary/CollapsableTableWrapper'
import { CalculatedDeliverablesRating } from './useCalculatedDeliverablesRating'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableTypes } from '@src/interfaces/table'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  PipSmartGoalSideBar,
  PipSmartGoalSideBarForm,
  useSideBarVariant,
} from '@src/features/PIP/components/PipSmartGoalSideBar'
import {
  smartGoalBaseRow,
  useSmartGoalsTable,
} from '@src/features/PIP/components/PipSmartGoalsTable'
import { useCanSetPIPGoals } from '@src/features/PIP/permissions/usePipGoalsPermissions'

interface Props {
  employeeId: number
  isSummaryReview?: boolean
  selectedCycle: PerformanceSelector
  calculatedDeliverablesRating?: CalculatedDeliverablesRating | null
}

export const SummaryPipGoalsCollapsable = ({
  employeeId,
  selectedCycle,
  calculatedDeliverablesRating,
  isSummaryReview,
}: Props) => {
  const canSetPIPGoals = useCanSetPIPGoals(employeeId)
  const table = useSmartGoalsTable(employeeId)
  const [selectedGoal, setSelectedGoal] = useState<DeliverableInterface | null>(null)
  const { variant, setEdit, setAdd, setDetails, clear } = useSideBarVariant()

  const clearVariantAndClose = () => {
    clear()
    setSelectedGoal(null)
  }

  const onGoalUpdated = (goal: DeliverableInterface) => {
    setSelectedGoal(goal)
    setDetails()
    table.refresh()
  }

  const onGoalDeleted = () => {
    clearVariantAndClose()
    table.refresh()
  }

  const handleAddGoal = () => {
    setSelectedGoal(null)
    setAdd()
  }

  const handleEditGoal = (goal: DeliverableInterface) => {
    setSelectedGoal(goal)
    setEdit()
  }

  const onAfterSubmit = () => {
    clearVariantAndClose()
    table.refresh()
  }

  const additionalContent =
    canSetPIPGoals && table.data.length > 0 ? (
      <ActionButton useIcon="Plus" my={9} mx={16} onClick={handleAddGoal}>
        Add PIP goals
      </ActionButton>
    ) : null

  const showActions = variant === 'edit' || variant === 'add'

  return (
    <Box mt="s-16">
      <CollapsableTableWrapper
        mapperFunc={calculatedDeliverablesRating?.mapper}
        gradesMap={calculatedDeliverablesRating?.gradesMap}
        completedReviews={isSummaryReview}
        icon={<Icon name="Target" color={Token.color.onAccent} size={24} />}
        headerTitle="Goals"
        headerRating={calculatedDeliverablesRating?.rating}
        count={table.data.length}
        ratings={calculatedDeliverablesRating?.ratings}
        additionalContent={additionalContent}
      >
        <AdjustableTable<DeliverableInterface>
          name={TableNames.PipSummaryGoals}
          type={TableTypes.Contained}
          emptyState={
            <EmptyTableRaw
              imageId="3D304"
              title="PIP goals pending"
              description="Line Manager needs to provide PIP goals"
              action={
                canSetPIPGoals ? (
                  <ActionButton useIcon="Plus" onClick={handleAddGoal}>
                    Add PIP goals
                  </ActionButton>
                ) : null
              }
            />
          }
          row={smartGoalBaseRow}
          onRowClick={(goal: DeliverableInterface) => {
            setDetails()
            setSelectedGoal(goal)
          }}
          {...table}
        />

        {!!variant && (
          <PipSmartGoalSideBar onClose={clearVariantAndClose} variant={variant}>
            <PipSmartGoalSideBarForm
              goal={selectedGoal ?? undefined}
              pipCycle={selectedCycle}
              variant={variant}
              employeeId={employeeId}
              onGoalDeleted={onGoalDeleted}
              onGoalUpdated={onGoalUpdated}
              onEditClick={handleEditGoal}
            >
              {showActions && (
                <Side.Actions horizontal>
                  <Button variant="secondary" onClick={clearVariantAndClose}>
                    Cancel
                  </Button>
                  <NewSaveButtonWithPopup
                    useValidator
                    noPopup
                    onAfterSubmit={onAfterSubmit}
                    hideWhenNoChanges={false}
                  >
                    Confirm
                  </NewSaveButtonWithPopup>
                </Side.Actions>
              )}
            </PipSmartGoalSideBarForm>
          </PipSmartGoalSideBar>
        )}
      </CollapsableTableWrapper>
    </Box>
  )
}
