import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Color,
  Flex,
  HStack,
  Icon,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'

import { rowWrapperMinHeight } from '@src/components/TableV2/common'
import { mapScoreToCellColor } from './helpers'
import { TrendIndicator } from '../TrendIndicator'

const HEATMAP_ITEM_HEIGHT = rowWrapperMinHeight.heatmap - 8 // reduced to visually separate the rows
export const HEATMAP_ITEM_MIN_WIDTH = 170

interface HeatmapTableCellData {
  anonymized: boolean
  name: string
  average_score: number
  max_value?: number
  min_value?: number
  trend_average_score: number | null
  comparisonScore: number | null
}

const CellWrapper = ({
  children,
  color,
  isParent,
}: {
  children: React.ReactNode
  color: Color
  isParent?: boolean
}) => {
  return (
    <Box
      width={isParent ? '100%' : 'calc(100% - 12px)'}
      height={HEATMAP_ITEM_HEIGHT}
      radius={Token.radius.r12}
      backgroundColor={color}
      ml={isParent ? undefined : '12px'}
    >
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Flex>
    </Box>
  )
}

export const HeatmapTableCell = ({
  data,
  isParent,
}: {
  data?: HeatmapTableCellData
  isParent?: boolean
}) => {
  const tooltip = useTooltip()
  if (data?.anonymized) {
    return (
      <CellWrapper color={Token.color.greyTone8} isParent={isParent}>
        <Icon
          name="EyeHide"
          color={Token.color.greyTone20}
          size={16}
          {...tooltip.getAnchorProps()}
        />
        <Tooltip placement="top" {...tooltip.getTargetProps()}>
          This result is hidden due to the anonymity threshold
        </Tooltip>
      </CellWrapper>
    )
  }
  if (data?.average_score == null) {
    return (
      <CellWrapper color={Token.color.greyTone8} isParent={isParent}>
        <Icon
          name="MinusCircle"
          color={Token.color.greyTone20}
          size={16}
          {...tooltip.getAnchorProps()}
        />
        <Tooltip placement="top" {...tooltip.getTargetProps()}>
          This result is not available
        </Tooltip>
      </CellWrapper>
    )
  }

  return (
    <CellWrapper color={mapScoreToCellColor(data.average_score)} isParent={isParent}>
      <HStack space="s-2" align="end">
        <Text fontWeight={500} lineHeight={1} color={Token.color.greyTone50}>
          <FormattedMessage
            id={'engagement.heatmapTable.averageScore'}
            defaultMessage={`<T>${data.average_score}</T> / 10`}
            values={{
              T: chunks => (
                <Text variant="heading3" lineHeight={1} color={Token.color.foreground}>
                  {chunks}
                </Text>
              ),
            }}
          />
        </Text>
      </HStack>
      <HStack mt="s-8" gap="s-8" align="center">
        <TrendIndicator
          avgScore={data.average_score}
          trendAvgScore={data.trend_average_score}
        />
        {typeof data.comparisonScore === 'number' && (
          <HStack space="s-2" align="center">
            <Text variant="body3" color={Token.color.greyTone50}>
              {data.comparisonScore}
            </Text>
            <Icon color={Token.color.greyTone50} size={12} name="CompanyOutline" />
          </HStack>
        )}
      </HStack>
    </CellWrapper>
  )
}
