import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Box, Flex } from '@revolut/ui-kit'
import { useGetEmployee } from '@src/api/pip'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageWrapper } from '@components/Page/Page'
import PageLoading from '@components/PageLoading/PageLoading'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { PipRouteParams } from './interfaces'
import PipSettingsForm from './PipSettings'
import PipGoalSettingsForm from './PipGoalSettings'

const getPipFormTabs = (params: PipRouteParams) =>
  [
    {
      title: 'Settings',
      path: ROUTES.FORMS.PIP.SETTINGS,
      to: pathToUrl(ROUTES.FORMS.PIP.SETTINGS, params),
      component: PipSettingsForm,
    },
    {
      title: 'Goals',
      path: ROUTES.FORMS.PIP.GOAL_SETTINGS,
      to: pathToUrl(ROUTES.FORMS.PIP.GOAL_SETTINGS, params),
      component: PipGoalSettingsForm,
    },
  ].filter(Boolean)

export const PipV2 = () => {
  const params = useParams<PipRouteParams>()
  const { data: employee } = useGetEmployee(params.employeeId)

  if (!employee) {
    return <PageLoading />
  }

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params)

  const isNewInstance = !params?.id
  const tabs = isNewInstance ? null : getPipFormTabs(params)

  return (
    <PageWrapper>
      <PageHeader
        backUrl={backUrl}
        subtitle={<UserWithAvatar {...employee} asText />}
        title="Initiate PIP"
      />
      {tabs && (
        <Box mb="s-8">
          <TabBarNavigation tabs={tabs} />
        </Box>
      )}
      <PageBody>
        <Flex flex={1} flexDirection="column">
          <Switch>
            <Route exact path={ROUTES.FORMS.PIP.SETTINGS}>
              <PipSettingsForm employee={employee} />
            </Route>
            <Route exact path={ROUTES.FORMS.PIP.GOAL_SETTINGS}>
              <PipGoalSettingsForm employee={employee} />
            </Route>
          </Switch>
        </Flex>
      </PageBody>
    </PageWrapper>
  )
}
