import React from 'react'
import { HStack, Icon, Token, Text } from '@revolut/ui-kit'

import { formatPercentage } from '@src/utils/format'
import { getTrendScoreColor } from '@src/features/Engagement/helpers/statValuesToColor'

interface Props {
  avgScore: number | null | undefined
  trendAvgScore: number | null | undefined
  variant?: 'xs' | 'sm'
  colorized?: boolean
}
export const TrendIndicator = ({
  avgScore,
  trendAvgScore,
  variant = 'xs',
  colorized,
}: Props) => {
  if (avgScore == null || trendAvgScore == null) {
    return null
  }
  const trendDiff = avgScore - trendAvgScore
  const trendDiffFormatted = formatPercentage(trendDiff / 10)
  const color = colorized ? getTrendScoreColor(trendDiff) : Token.color.greyTone50
  const iconProps = { color, size: variant === 'xs' ? 12 : 15 }

  if (trendDiff === 0 || trendDiffFormatted === '0%') {
    return (
      <HStack space="s-2" align="center">
        <Icon {...iconProps} name="ArrowTopDown" />
        <Text variant={variant === 'xs' ? 'body3' : 'emphasis1'} color={color}>
          {trendDiffFormatted}
        </Text>
      </HStack>
    )
  }
  return (
    <HStack space="s-2" align="center">
      <Icon {...iconProps} name={trendDiff > 0 ? 'ArrowRates' : 'ArrowRatesDown'} />
      <Text variant={variant === 'xs' ? 'body3' : 'emphasis1'} color={color}>
        {trendDiff > 0 ? `+${trendDiffFormatted}` : trendDiffFormatted}
      </Text>
    </HStack>
  )
}
