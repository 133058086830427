import {
  PerformanceSelector,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import React, { useMemo } from 'react'
import { useGetPermissions } from '../hooks/useGetPermissions'
import { Icon, MoreBar, Token, useToggle } from '@revolut/ui-kit'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { deleteEmployeeCycle } from '@src/api/probationEmployees'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import {
  checkCanViewPIPv2Settings,
  checkRejectAvailability,
  getReviewer,
  isNewFlowRequestsResponse,
  isRequestFeedbackInterfaceArray,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { useGetPeriodTypes } from '@src/utils/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { regeneratePipScorecards, useGetPipCheckpoints } from '@src/api/pip'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

import { rejectReview } from '@src/api/performanceReview'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import RequestFeedback from '@src/pages/EmployeeProfile/Preview/Performance/PerformanceReview/RequestFeedback'
import SideBar from '@src/components/SideBar/SideBar'
import { ProbationPipAdditionalInfo } from '@src/pages/EmployeeProfile/Layout/Performance/ProbationPipAdditionalInfo'
import { PipTimelineSettingsForm } from '../../TimelineSettings/PipTimelineSettingsForm'
import { useInvalidatePerformanceSettingsQuery } from '@src/api/performance'

interface Props {
  selectedPeriod: PerformanceSelector
  employee: EmployeeInterface
}

export const PipCycleActions = ({ selectedPeriod, employee }: Props) => {
  const permissions = useGetPermissions({ employee })
  const confirmationPopup = useConfirmationDialog()
  const errorPopup = useErrorPopup()
  const [isPIPNotesSidebarOpen, togglePIPNotesSidebar] = useToggle()
  const [isReviewSidebarOpen, toggleReviewsSidebar] = useToggle()
  const [isTimelineSettingsidebarOpen, toggleTimelineSettingsSidebar] = useToggle()
  const canViewPIPv2Settings = checkCanViewPIPv2Settings(selectedPeriod)
  const { isPIPv2 } = useGetPeriodTypes(selectedPeriod)
  const showStatusPopup = useShowStatusPopup()
  const { isNewFlow, isNewPerformance } = useGetPeriodTypes(selectedPeriod)

  const { data: pipCheckpoints, refetch: refetchPipCheckpoints } = useGetPipCheckpoints(
    employee.id,
    selectedPeriod?.id !== undefined ? String(selectedPeriod?.id) : null,
  )
  const invalidatePerformanceSettingQueries = useInvalidatePerformanceSettingsQuery()

  const user = useSelector(selectUser)

  const {
    data: performanceReviewRequestsData,
    isLoading,
    refetch,
  } = useFetcherPerformanceRequests({
    category: selectedPeriod.category,
    isNewFlow,
    id: employee.id,
    performanceCycle: selectedPeriod,
  })

  const performanceReviewRequests = isNewFlow
    ? isNewFlowRequestsResponse(performanceReviewRequestsData)
      ? performanceReviewRequestsData.results
      : undefined
    : isRequestFeedbackInterfaceArray(performanceReviewRequestsData)
    ? performanceReviewRequestsData
    : undefined

  const userReviewRequests = useMemo(() => {
    if (!Array.isArray(performanceReviewRequests)) {
      return undefined
    }

    return (
      performanceReviewRequests as (
        | PerfReviewRequestFeedbackInterface
        | RequestFeedbackInterface
      )[]
    ).find(request => getReviewer(request, isNewPerformance)?.id === user.id)
  }, [performanceReviewRequests, user.id, isNewPerformance])

  const handleRejectReview = async () => {
    if (userReviewRequests?.id) {
      try {
        await rejectReview(employee.id, userReviewRequests?.id)
        showStatusPopup({
          status: 'success',
          title: 'Review request successfully rejected.',
        })
        invalidatePerformanceSettingQueries()
      } catch (error) {
        errorPopup.show({
          error,
          fallbackTitle: `Couldn't reject review`,
          forceFallbackTitle: true,
        })
      }
    }
  }

  const onDeleteEmployeeCycle = async () => {
    try {
      await deleteEmployeeCycle(getReviewCycleIdWithoutPrefix(selectedPeriod?.id))
    } catch (error) {
      errorPopup.show({
        error,
        fallbackTitle: `Couldn't delete PIP cycle`,
        forceFallbackTitle: true,
      })
    }
  }

  const handleRegenerateScorecards = async () => {
    try {
      await regeneratePipScorecards(getReviewCycleIdWithoutPrefix(selectedPeriod.id))
      refetch()
      refetchPipCheckpoints()
      showStatusPopup({
        status: 'success',
        title: 'Scorecards regenerated',
      })
    } catch (error) {
      errorPopup.show({ error, fallbackTitle: 'Could not regenerate scorecards' })
    }
  }

  return (
    <>
      <MoreBar.Action onClick={togglePIPNotesSidebar.on} useIcon="Document">
        PIP notes
      </MoreBar.Action>
      {permissions.hasProbationCommitteeHRPermissions && (
        <MoreBar.Action useIcon="CalendarWeek" onClick={toggleTimelineSettingsSidebar.on}>
          Timeline settings
        </MoreBar.Action>
      )}
      <MoreBar.Action
        useIcon="16/ArrowRecurring"
        onClick={() =>
          confirmationPopup.show({
            label: 'Regenerate scorecard for this cycle?',
            yesMessage: 'Regenerate',
            noMessage: 'Cancel',
            onConfirm: handleRegenerateScorecards,
          })
        }
      >
        Regenerate scorecards
      </MoreBar.Action>
      {isPIPv2 && canViewPIPv2Settings && (
        <MoreBar.Action
          use={InternalLink}
          useIcon="CandlestickChart"
          to={pathToUrl(ROUTES.FORMS.PIP.SETTINGS, {
            employeeId: employee.id,
            id: selectedPeriod.id,
          })}
        >
          Setup
        </MoreBar.Action>
      )}
      {permissions.canRequestReview && (
        <MoreBar.Action use="button" useIcon="Plus" onClick={toggleReviewsSidebar.switch}>
          Request review
        </MoreBar.Action>
      )}

      {checkRejectAvailability(userReviewRequests) ? (
        <MoreBar.Action
          use="button"
          useIcon={<Icon size={16} name="Reverted" color={Token.color.red} />}
          onClick={() =>
            confirmationPopup.show({
              label: 'Reject review',
              body: 'Are you sure?',
              yesMessage: 'Reject',
              noMessage: 'Cancel',
              onConfirm: handleRejectReview,
            })
          }
        >
          Reject review request
        </MoreBar.Action>
      ) : undefined}
      <MoreBar.Action
        variant="negative"
        useIcon="Delete"
        onClick={() =>
          confirmationPopup.show({
            label: 'Delete this cycle?',
            yesMessage: 'Delete',
            noMessage: 'Cancel',
            onConfirm: onDeleteEmployeeCycle,
          })
        }
      >
        Delete PIP cycle
      </MoreBar.Action>
      <SideBar
        variant="wide"
        usePortal
        useLayout
        title="PIP timeline settings"
        useIcon="CalendarWeek"
        isOpen={isTimelineSettingsidebarOpen}
        onClose={toggleTimelineSettingsSidebar.off}
      >
        <PipTimelineSettingsForm
          cycleId={selectedPeriod.cycle_id}
          employeeId={employee.id}
          placement="sidebar"
          onAfterSubmit={() => {
            toggleTimelineSettingsSidebar.off()
            refetchPipCheckpoints()
          }}
        />
      </SideBar>
      <SideBar
        variant="wide"
        usePortal
        useLayout
        title="PIP notes"
        useIcon="Document"
        isOpen={isPIPNotesSidebarOpen}
        onClose={togglePIPNotesSidebar.off}
      >
        <ProbationPipAdditionalInfo data={employee} selectedPeriod={selectedPeriod} />
      </SideBar>
      {isReviewSidebarOpen && (
        <RequestFeedback
          canRequest={permissions.canRequestReview}
          performanceCycle={selectedPeriod}
          isNewFlow={isNewFlow}
          category={ReviewCategory.PIP_V2}
          onClose={toggleReviewsSidebar.off}
          id={employee.id}
          requests={performanceReviewRequests}
          onAddRequest={invalidatePerformanceSettingQueries}
          checkpoints={pipCheckpoints?.checkpoints}
          fetching={isLoading}
        />
      )}
    </>
  )
}
