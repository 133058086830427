import React from 'react'
import { ActionButton, Widget } from '@revolut/ui-kit'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { ROUTES } from '@src/constants/routes'
import { useIsEngagementManager } from '../hooks/permissions'

export const resultsNotFoundTitle = 'Survey results were not found'

export const NoSurveysPlaceholder = ({ variant }: { variant?: 'widget' | 'default' }) => {
  const isEngagementManager = useIsEngagementManager()
  const Wrapper = variant === 'widget' ? Widget : React.Fragment

  return (
    <Wrapper>
      <EmptyTableRaw
        title={resultsNotFoundTitle}
        description={
          isEngagementManager
            ? 'You should run a survey and publish results to see them here'
            : undefined
        }
        action={
          isEngagementManager ? (
            <ActionButton use={InternalLink} to={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD}>
              Go to dashboard
            </ActionButton>
          ) : undefined
        }
      />
    </Wrapper>
  )
}
