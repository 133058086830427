import React, { useMemo } from 'react'
import {
  Group,
  HStack,
  Separator,
  Subheader,
  Tag,
  Token,
  VStack,
  Link as UIKitLink,
  Icon,
  Flex,
  Box,
  Copyable,
  Ellipsis,
  Action,
  Cell,
} from '@revolut/ui-kit'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { Link } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import WorkExperienceInfo from '@src/features/InterviewTool/WorkExperienceInfo'
import EducationInfo from '@src/features/InterviewTool/EducationInfo'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { navigateTo } from '@src/actions/RouterActions'
import ChangeRound from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/ChangeRound'
import OpenInNewTab from '@components/OpenInNewTab/OpenInNewTab'
import { getLinkedinUrl } from '@src/utils/hiring'
import CandidateTags from '@src/features/InterviewTool/CandidateTags'
import { CVInsightsWidget } from '@src/features/CVInsightsWidget/CVInsightsWidget'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { FeatureFlags } from '@src/store/auth/types'
import { Info } from '@src/pages/Forms/CVScreening/components/Info'

type Props = {
  candidate: CandidateInterface
  round: InterviewRoundInterface
}

const CvScreeningSide = ({ candidate, round }: Props) => {
  const isCVScoringEnabled = useHasFeatureFlag(FeatureFlags.CVScoring)
  const { data: originSelector } = useGetSelectors(
    round?.origin ? selectorKeys.candidate_origin_choices : null,
  )
  const origin = useMemo(
    () => originSelector?.find(item => item.id === round?.origin)?.name,
    [originSelector, round],
  )
  const linkedIn = getLinkedinUrl(candidate.links)
  const links = candidate.links?.filter(link => link !== linkedIn)

  return (
    <VStack gap="s-16" width="100%" maxWidth={408}>
      {isCVScoringEnabled ? (
        <CVInsightsWidget roundId={round.id} specialisationId={round.specialisation.id} />
      ) : null}

      {isCVScoringEnabled ? null : (
        <>
          <Group>
            <Subheader variant="default">
              <Subheader.Title>
                <UIKitLink
                  use={Link}
                  to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidate.id })}
                  target="_blank"
                  color="inherit"
                >
                  <HStack gap="s-8" align="center">
                    {candidate.full_name} <Icon name="LinkExternal" size={18} />
                  </HStack>
                </UIKitLink>
              </Subheader.Title>
              {origin && (
                <Subheader.Side>
                  <Tag useIcon="Document" variant="outlined" color={Token.color.deepGrey}>
                    {origin}
                  </Tag>
                </Subheader.Side>
              )}
            </Subheader>
            <Separator />
            <Info candidate={candidate} round={round} />
          </Group>

          <Cell>
            <CandidateTags candidate={candidate} />
          </Cell>

          <WorkExperienceInfo data={candidate.work_experiences} />

          <EducationInfo data={candidate.educations} />
        </>
      )}

      {isCVScoringEnabled ? null : (
        <FormPreview
          data={candidate}
          title="Personal details"
          // @TODO: open in current page or open in a new tab with opened sidebar
          onEdit={() =>
            navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidate.id }))
          }
        >
          <Group>
            <FormPreview.Item<CandidateInterface>
              title="Email"
              insert={data =>
                data.email ? (
                  <Flex alignItems="center">
                    <Box mr="10px">
                      <Copyable
                        value={data.email}
                        labelButtonCopy="Copy"
                        onCopy={() => {
                          pushNotification({
                            value: 'Email copied to clipboard',
                            duration: SUCCESS_DEFAULT_DURATION,
                            type: NotificationTypes.success,
                          })
                        }}
                      />
                    </Box>
                    <Ellipsis>{data.email}</Ellipsis>
                  </Flex>
                ) : (
                  '-'
                )
              }
            />

            {linkedIn && (
              <FormPreview.Item
                title="Linkedin"
                insert={() => (
                  <UIKitLink use="a" href={linkedIn} target="_blank">
                    <Action useIcon="LogoLinkedIn">Open in new tab</Action>
                  </UIKitLink>
                )}
              />
            )}

            {!!links?.length && (
              <FormPreview.Item
                title="Links"
                insert={() => links.map(link => <OpenInNewTab key={link} link={link} />)}
              />
            )}
          </Group>
        </FormPreview>
      )}

      <ChangeRound candidateId={candidate.id} activeRoundId={round.id} readOnly />
    </VStack>
  )
}

export default CvScreeningSide
