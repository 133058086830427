import { Box, InputGroup, MoreBar } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DeleteButton } from '@src/features/SettingsButtons'
import { selectUser } from '@src/store/auth/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'
import { getFieldSetter } from '@src/utils/setDefaultField'
import { connect } from 'lape'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useGetTabSections } from '../api'
import { DepartmentTabSectionInterface } from '../interfaces'
import { deleteDepartmentResouce, departmentResourcesRequests } from './api'
import { goBack } from '@src/actions/RouterActions'

const DepartmentResourceForm = () => {
  const params = useParams<{ departmentId?: string; tabId?: string }>()

  const { query } = useQuery<{ initialSection?: string }>()

  const user = useSelector(selectUser)

  const { values } = useLapeContext<DepartmentTabSectionInterface>()

  const { data: sectionData, isLoading: isTabSectionsLoading } = useGetTabSections(params)

  const isMandatory = !!values.placeholder

  const isExistingResource = !!values.id

  const backUrl = pathToUrl(ROUTES.FORMS.DEPARTMENT.RESOURCES.TOTAL, {
    id: params.departmentId,
  })

  const setDefaultIfEmpty = getFieldSetter<DepartmentTabSectionInterface>({ values })

  useEffect(() => {
    setDefaultIfEmpty('owner', { id: user.id, name: user.full_name })

    if (query.initialSection && sectionData?.sections?.length) {
      const selectedSection = sectionData?.sections?.find(
        section => `${section.id}` === query.initialSection,
      )
      if (selectedSection) {
        setDefaultIfEmpty('section', {
          id: selectedSection.id,
          name: selectedSection.name,
        })
      }
    }
  }, [user, query.initialSection, sectionData])

  const title = (() => {
    if (isMandatory) {
      return isExistingResource ? 'Edit mandatory resource' : 'Add mandatory resource'
    }
    return isExistingResource ? 'Edit resource' : 'Add resource'
  })()

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />

      <PageBody>
        {isExistingResource && !isMandatory && (
          <Box mb="s-32">
            <MoreBar>
              <DeleteButton
                deleteApi={() =>
                  deleteDepartmentResouce({
                    id: values.id,
                    departmentId: Number(params.departmentId),
                    tabId: Number(params.tabId),
                  })
                }
                backUrl={backUrl}
                title="resource"
                useGenericErrorHandler
                forceShow
              />
            </MoreBar>
          </Box>
        )}

        <InputGroup>
          <LapeRadioSelectInput
            label="Resource section"
            name="section"
            required
            options={
              sectionData?.sections?.map(section => ({
                value: { id: section.id, name: section.name },
                label: section.name,
              })) || []
            }
            loading={isTabSectionsLoading}
            disabled={isMandatory}
          />
          <LapeNewInput label="Name" name="name" required disabled={isMandatory} />
          <LapeRadioSelectInput
            label="Owner"
            name="owner"
            required
            selector={selectorKeys.employee}
          />
          <LapeNewTextArea label="Description" name="description" required />
          <LapeNewInput label="Link" name="value" required />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup useValidator onAfterSubmit={() => goBack()} />
      </PageActions>
    </PageWrapper>
  )
}

export const DepartmentResource = connect(() => (
  <Form api={departmentResourcesRequests} disableLocalStorageCaching>
    <DepartmentResourceForm />
  </Form>
))
