import React from 'react'
import { Avatar, Group, Subheader, VStack } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { AiIcon } from '@components/AiIcon/AiIcon'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { employeeSettingsRequests } from '@src/api/employees'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { EmployeeSettingsInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getMessageFromApiError } from '@src/store/notifications/actions'
import { InterviewerProfile } from '@src/features/InterviewerProfile/InterviewerProfile'

const InterviewerProfileForm = () => {
  const user = useSelector(selectUser)
  const showStatusPopup = useShowStatusPopup()
  const { submit, reset, initialValues } = useLapeContext<EmployeeSettingsInterface>()

  const onSubmit = async () => {
    try {
      await submit()
    } catch (error) {
      showStatusPopup({
        title: 'Cannot save changes',
        description: getMessageFromApiError(error),
        status: 'error',
      })

      // as initialValues is Partial, but reset takes not Partial, prefill all optional fields
      reset({
        email_integration_enabled: false,
        calendar_integration_enabled: false,
        field_options: {
          no_access: [],
          read_only: [],
        },
        office_suite_provider: null,
        consent_to_interview_ai_summarisation: null,
        ...initialValues,
      })
    }
  }

  return (
    <VStack gap="s-16">
      <InterviewerProfile interviewerId={user.id} />
      <Group>
        <Subheader variant="nested">Interview preferences</Subheader>
        <LapeNewSwitch
          name="consent_to_interview_ai_summarisation"
          itemTypeProps={{
            title: 'Enable AI',
            description:
              'When enabled, meeting transcription and interview summary will be provided in the interview scorecard and interview analytics will be provided in your interviewer profile.',
            avatar: (
              <Avatar variant="brand" size={40}>
                <AiIcon />
              </Avatar>
            ),
          }}
          onAfterChange={onSubmit}
        />
      </Group>
    </VStack>
  )
}

interface InterviewerProfileSidebarProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const InterviewerProfileSidebar = ({
  isOpen,
  onClose,
}: InterviewerProfileSidebarProps) => {
  const user = useSelector(selectUser)
  return (
    <SideBar isOpen={isOpen} title="My interviewer profile" onClose={onClose}>
      <Form
        api={employeeSettingsRequests}
        forceParams={{ id: String(user.id) }}
        isExistingForm
      >
        <InterviewerProfileForm />
      </Form>
    </SideBar>
  )
}
