import { opacity, Token } from '@revolut/ui-kit'

export const mapScoreToCellColor = (score?: number) => {
  const neutralColor = Token.color.greyTone2

  if (!score) {
    return neutralColor
  }

  const MIN_SCORE = 0
  const MID_SCORE = 5
  const MAX_SCORE = 10
  const DIFF_THRESHOLD = 0.01

  const isDanger = score < MID_SCORE && score >= MIN_SCORE
  const dangerOpacity = 1 - score / (MID_SCORE || DIFF_THRESHOLD)

  const isSuccess = score >= MID_SCORE && score <= MAX_SCORE
  const successOpacity = (score - MID_SCORE) / (MAX_SCORE - MID_SCORE || DIFF_THRESHOLD)

  if (score === MID_SCORE) {
    return neutralColor
  }
  if (isDanger) {
    return opacity(Token.colorChannel.red, dangerOpacity)
  }
  if (isSuccess) {
    return opacity(Token.colorChannel.green, successOpacity)
  }
  return neutralColor
}
