import { pipGoalsTableRequests } from '@src/api/pip'
import {
  pipReviewDueColumn,
  pipReviewGoalColumn,
  pipStatusColumn,
} from '@src/constants/columns/pip'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/Table/hooks'
import { toIdAndName } from '@src/utils/toIdAndName'

const getPipGoalsFilter = (employeeId: string | number) => {
  return [
    {
      filters: [toIdAndName(String(employeeId))],
      columnName: 'employee__id',
    },
    {
      filters: [toIdAndName('PIP')],
      columnName: 'category',
    },
  ]
}

export const smartGoalBaseRow: RowInterface<DeliverableInterface> = {
  cells: [
    {
      ...pipReviewGoalColumn,
      width: 100,
    },
    {
      ...pipReviewDueColumn,
      width: 30,
    },
    {
      ...pipStatusColumn,
      width: 30,
    },
  ],
}

export const useSmartGoalsTable = (employeeId: string | number) => {
  const tableRequests = pipGoalsTableRequests(employeeId)
  const filter = getPipGoalsFilter(employeeId)
  return useTable(tableRequests, filter)
}
