import React, { useEffect } from 'react'
import {
  MoreBar,
  MoreBarProps,
  MoreBarSkeleton,
  TextSkeleton,
  Text,
  HStack,
  useTooltip,
  Tooltip,
  Token,
  Caption,
} from '@revolut/ui-kit'
import { useGetRoadmapSettings } from '@src/api/settings'
import MoreInfoButton from '@src/components/MoreInfoButton/MoreInfoButton'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { useCallback, useState } from 'react'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import { Retry } from '@revolut/icons'
import { RoadmapSideBar } from '@src/pages/Forms/RoadmapForm/common/RoadmapSideBar'
import { checkRefreshEpicsStatus, refreshEpics } from '@src/api/roadmaps'
import { getLocalDateTime, getLocalDistanceTime } from '@src/utils/roadmap'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import Table from '@src/components/TableV2/Table'
import pluralize from 'pluralize'
import { useErrorPopup } from '../Errors/useErrorPopup'

export interface RoadmapContext {
  isLoading: boolean
  noDataLabel: string
  dataType: string
}

export const useShowRoadmapDetails = ({
  changelogState,
}: {
  changelogState: boolean
}) => {
  const [selectedRow, setSelectedRow] = useState<RoadmapInterface>()
  const showRoadmapDetails = useCallback(
    async (row: RoadmapInterface) => {
      if (row.epic_url) {
        window.open(row.epic_url, '_blank')
      } else if (!changelogState) {
        setSelectedRow(row)
      }
    },
    [changelogState],
  )

  const renderSidebar = ({
    onAfterUpdate = () => {},
    canEdit,
  }: {
    canEdit: boolean
    onAfterUpdate?: () => void
  }) => {
    return (
      !!selectedRow && (
        <RoadmapSideBar
          canEdit={!!canEdit}
          data={selectedRow}
          onAfterSubmit={onAfterUpdate}
          onClose={() => setSelectedRow(undefined)}
        />
      )
    )
  }

  return {
    renderSidebar,
    showRoadmapDetails,
  }
}

interface OptionalRoadmapActionsProps {
  refresh: () => Promise<void>
  allowRefresh: boolean
}

export const OptionalRoadmapActions = ({
  refresh,
  allowRefresh,
}: OptionalRoadmapActionsProps) => {
  const [pending, setPending] = useState(false)
  const { data } = useGetRoadmapSettings()
  const errorPopup = useErrorPopup()
  const handleRefresh = async () => {
    try {
      setPending(true)
      await refresh()
      pushNotification({
        value:
          'Your request to refresh roadmap is added to the que and will be executed shortly.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } catch (error) {
      errorPopup.show({ error, fallbackTitle: 'Could not refresh roadmap' })
    } finally {
      setPending(false)
    }
  }

  return data?.jira_epics_enabled ? (
    <>
      {allowRefresh && (
        <MoreBar.Action pending={pending} onClick={handleRefresh} useIcon={Retry}>
          Refresh Now
        </MoreBar.Action>
      )}
      <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
    </>
  ) : null
}

export type RoadmapActionsBarProps = React.PropsWithChildren<{
  pending?: boolean
}> &
  MoreBarProps
export const RoadmapActionsBar = ({ pending, children }: RoadmapActionsBarProps) => {
  return pending ? (
    <MoreBarSkeleton data-testid="roadmap-action-bar-skeleton" />
  ) : (
    <Table.Widget.MoreBar maxCount={3}>{children}</Table.Widget.MoreBar>
  )
}

export const useRoadmapRefresh = ({
  epics,
  onAfterRefresh,
}: {
  epics: RoadmapInterface['key'][]
  onAfterRefresh: () => void
}) => {
  const errorPopup = useErrorPopup()
  const { data: settings } = useGetRoadmapSettings()
  const lsKey = 'roadmap_refresh_pending_task'
  const dateTooltip = useTooltip()
  const buttonTooltip = useTooltip()
  const [pending, setPending] = useState(false)
  const savedTaskId = workspaceLocalStorage.getItem(lsKey)
  const [pendingTaskId, setPendingTaskIdState] = useState<string | null>(savedTaskId)
  const setPendingTaskId = (jobId: string | null) => {
    setPendingTaskIdState(jobId)
    jobId
      ? workspaceLocalStorage.setItem(lsKey, jobId)
      : workspaceLocalStorage.removeItem(lsKey)
  }

  useEffect(() => {
    if (pendingTaskId) {
      scheduleRefreshCheck({
        taskId: pendingTaskId,
        interval: 1000,
      })
    }
  }, [])

  const triggerRefresh = async () => {
    setPending(true)
    try {
      const result = await refreshEpics({ data: { epics } })
      setPendingTaskId(result.data.task_id)
      scheduleRefreshCheck({ taskId: result.data.task_id })
    } catch (error) {
      errorPopup.show({ error, fallbackTitle: 'Could not refresh roadmap' })
    } finally {
      setPending(false)
    }
  }

  const scheduleRefreshCheck = ({
    taskId,
    interval = 1000, // one second
  }: {
    taskId: string
    interval?: number
  }) => {
    setTimeout(async () => {
      const result = await checkRefreshEpicsStatus(taskId)
      switch (result.data.status) {
        case 'PENDING':
          scheduleRefreshCheck({
            taskId,
            interval: interval * 1.1,
          })
          break
        case 'FAILURE':
          errorPopup.show({
            error: null,
            fallbackTitle: 'Could not refresh roadmap',
            fallbackDescription: 'Something went wrong, try again later',
          })
          setPendingTaskId(null)
          break
        case 'SUCCESS':
          setPendingTaskId(null)
          onAfterRefresh()
          break
      }
    }, interval)
  }

  const pendingUi = (
    <Text variant="caption" color={Token.color.greyTone50}>
      Your request to refresh roadmap is in progress. It can take up to 1 minute...
    </Text>
  )
  const renderRefreshNote = ({
    lastUpdated,
    chained,
  }: {
    lastUpdated?: string
    chained?: boolean
  }) =>
    pending ? (
      <TextSkeleton maxWidth="400px" />
    ) : (
      (pendingTaskId && pendingUi) ||
      (chained ? (
        <>
          {!!lastUpdated && (
            <>
              <Text
                variant="caption"
                color={Token.color.greyTone50}
                {...dateTooltip.getAnchorProps()}
                style={{ cursor: 'default' }}
              >
                last updated {getLocalDistanceTime(lastUpdated)} ago
              </Text>
              <Tooltip {...dateTooltip.getTargetProps()}>
                {getLocalDateTime(lastUpdated)}
              </Tooltip>
            </>
          )}
        </>
      ) : (
        <HStack color={Token.color.greyTone50} space="s-6" align="center">
          {!!lastUpdated && (
            <>
              <Text
                variant="caption"
                color={Token.color.greyTone50}
                {...dateTooltip.getAnchorProps()}
                style={{ cursor: 'default' }}
              >
                Progress last updated {getLocalDistanceTime(lastUpdated)} ago
              </Text>
              <Tooltip {...dateTooltip.getTargetProps()}>
                {getLocalDateTime(lastUpdated)}
              </Tooltip>
            </>
          )}
        </HStack>
      ))
    )

  const refreshButton = (
    <MoreBar.Action
      onClick={triggerRefresh}
      useIcon={Retry}
      pending={pending || !!pendingTaskId}
      {...buttonTooltip.getAnchorProps()}
    >
      Refresh Now
      <Tooltip {...buttonTooltip.getTargetProps()}>
        Refresh can take up to 1 minute
      </Tooltip>
    </MoreBar.Action>
  )

  const hasAnyIntegration = [
    settings?.clickup_enabled,
    settings?.jira_epics_enabled,
    settings?.linear_enabled,
  ].some(integration => !!integration)

  return {
    pendingTaskId,
    renderRefreshNote: hasAnyIntegration ? renderRefreshNote : () => null,
    refreshButton: hasAnyIntegration ? refreshButton : null,
    renderCount: (count: number, lastUpdated: string) => (
      <HStack align="center" space="s-8">
        <Caption color={Token.color.greyTone50}>
          Showing {pluralize('epic', count, true)}.
        </Caption>
        {renderRefreshNote({ lastUpdated })}
      </HStack>
    ),
  }
}
