import uniq from 'lodash/uniq'

import {
  createCustomFieldColumn,
  employeesAccessLevelColumn,
  employeesAddressLine1Column,
  employeesAddressLine2Column,
  employeesAddressLine3Column,
  employeesBankAccountCurrencyColumn,
  employeesBankAccountNameColumn,
  employeesBankNameColumn,
  employeesBirthDateColumn,
  employeesChangesEffectiveDateColumn,
  employeesCompensationBackdateCorrectionColumn,
  employeesContractTermColumn,
  employeesContractTypeColumn,
  employeesCountryColumn,
  employeesDepartmentColumn,
  employeesEmailColumn,
  employeesEmergencyContactEmailColumn,
  employeesEmergencyContactFullNameColumn,
  employeesEmergencyContactMobilePhoneColumn,
  employeesEmergencyContactPhoneCountryCodeColumn,
  employeesEmergencyContactPhoneNumberColumn,
  employeesEmergencyContactRelationshipColumn,
  employeesEndDateColumn,
  employeesEntityColumn,
  employeesFirstNameColumn,
  employeesFullTimeEquivalentColumn,
  employeesFunctionalManagerColumn,
  employeesJobTitleColumn,
  employeesJoiningBonusAmountColumn,
  employeesJoiningBonusColumn,
  employeesJoiningBonusCurrencyColumn,
  employeesLanguagesColumn,
  employeesLastNameColumn,
  employeesLegalSexColumn,
  employeesLineManagerColumn,
  employeesLinkToJustificationColumn,
  employeesLocationColumn,
  employeesMaritalStatusColumn,
  employeesMiddleNameColumn,
  employeesNameColumn,
  employeesNationalitiesColumn,
  employeesNoticePeriodAfterProbationColumn,
  employeesNoticePeriodAfterProbationUnitColumn,
  employeesNoticePeriodDuringProbationColumn,
  employeesNoticePeriodDuringProbationUnitColumn,
  employeesPayGroupColumn,
  employeesPersonalEmailColumn,
  employeesPhoneCountryCodeColumn,
  employeesPhoneNumberShortColumn,
  employeesPreferredNameColumn,
  employeesRoleColumn,
  employeesSalaryAmountColumn,
  employeesSalaryCurrencyColumn,
  employeesSalaryPaymentFrequencyColumn,
  employeesSalaryTimeUnitColumn,
  employeesSeniorityColumn,
  employeesStartDateColumn,
  employeesStatusColumn,
  employeesTeamColumn,
  employeesWeeklyWorkingHoursColumn,
  employeesWorkModalityColumn,
} from '@src/constants/columns/employeesV2'
import { supportedEmployeeCustomFieldsTypes } from '@src/features/EditableEmployeesTable/common/constants'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { CustomFieldsForSectionInterface } from '@src/interfaces/customFields'
import { RowInterface } from '@src/interfaces/data'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'

export const createGetRow =
  (params?: { showEmployeeNameColumn: boolean }) =>
  (onCreate: CreateCallback, customFields: CustomFieldsForSectionInterface[] = []) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => {
    const { showEmployeeNameColumn = true } = params || {}

    return {
      cells: [
        showEmployeeNameColumn && {
          ...employeesNameColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmailColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesPersonalEmailColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesFirstNameColumn(options.onChange),
          width: 120,
        },
        {
          ...employeesMiddleNameColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesLastNameColumn(options.onChange),
          width: 120,
        },
        {
          ...employeesPreferredNameColumn(options.onChange),
          width: 120,
        },
        {
          ...employeesContractTypeColumn(options.onChange),
          width: 150,
        },
        {
          ...employeesTeamColumn(
            options.onChange,
            onChangeAction => onCreate('team', onChangeAction),
            false,
          ),
          width: 150,
        },
        {
          ...employeesRoleColumn(
            options.onChange,
            onChangeAction => onCreate('role', onChangeAction),
            false,
          ),
          width: 150,
        },
        {
          ...employeesSeniorityColumn(options.onChange, false),
          width: 150,
        },
        {
          ...employeesLineManagerColumn(options.onChange, false),
          width: 200,
        },
        {
          ...employeesFunctionalManagerColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesDepartmentColumn(options.onChange, onChangeAction =>
            onCreate('department', onChangeAction),
          ),
          width: 200,
        },
        {
          ...employeesEntityColumn(
            options.onChange,
            onChangeAction => onCreate('companyEntity', onChangeAction),
            false,
          ),
          width: 200,
        },
        {
          ...employeesLocationColumn(
            options.onChange,
            onChangeAction => onCreate('location', onChangeAction),
            false,
          ),
          width: 200,
        },
        {
          ...employeesBirthDateColumn(options.onChange),
          width: 145,
        },
        {
          ...employeesLegalSexColumn(options.onChange),
          width: 110,
        },
        {
          ...employeesMaritalStatusColumn(options.onChange),
          width: 150,
        },
        {
          ...employeesCountryColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesLanguagesColumn(options.onChange),
          width: 250,
        },
        {
          ...employeesNationalitiesColumn(options.onChange),
          width: 250,
        },
        {
          ...employeesPhoneCountryCodeColumn(options.onChange),
          width: 150,
        },
        {
          ...employeesPhoneNumberShortColumn(options.onChange),
          width: 150,
        },
        {
          ...employeesAddressLine1Column(options.onChange),
          width: 200,
        },
        {
          ...employeesAddressLine2Column(options.onChange),
          width: 200,
        },
        {
          ...employeesAddressLine3Column(options.onChange),
          width: 200,
        },
        {
          ...employeesBankNameColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesBankAccountNameColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesBankAccountCurrencyColumn(options.onChange),
          width: 110,
        },
        {
          ...employeesCompensationBackdateCorrectionColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmergencyContactEmailColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmergencyContactFullNameColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmergencyContactRelationshipColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmergencyContactMobilePhoneColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmergencyContactPhoneCountryCodeColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEmergencyContactPhoneNumberColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesJobTitleColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesStartDateColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesContractTermColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesEndDateColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesPayGroupColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesSalaryCurrencyColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesSalaryAmountColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesSalaryTimeUnitColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesSalaryPaymentFrequencyColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesJoiningBonusColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesJoiningBonusCurrencyColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesJoiningBonusAmountColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesWeeklyWorkingHoursColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesFullTimeEquivalentColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesLinkToJustificationColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesNoticePeriodDuringProbationColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesNoticePeriodDuringProbationUnitColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesNoticePeriodAfterProbationColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesNoticePeriodAfterProbationUnitColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesWorkModalityColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesAccessLevelColumn(options.onChange),
          width: 200,
        },
        {
          ...employeesChangesEffectiveDateColumn(options.onChange),
          width: 200,
        },
        { ...employeesStatusColumn(options.onChange), width: 160 },
        ...customFields.map(cf => {
          const isSupported = supportedEmployeeCustomFieldsTypes.includes(
            cf.input_type.id,
          )

          if (!isSupported) {
            return null
          }
          const customFieldColumn = createCustomFieldColumn(cf)
          return {
            ...customFieldColumn(options.onChange),
            width: 200,
          }
        }),
      ].filter(Boolean),
    }
  }

export const mandatoryBulkSessionFields = ['email']
export const optionalBulkSessionFields = uniq([
  ...baseFieldsForBulkEdit,
  'first_name',
  'last_name',
  'line_manager',
  'location',
  'access_level',
  'middle_name',
  'preferred_name',
  'functional_manager',
  'department',
  'entity',
  'country',
  'languages',
  'nationalities',
  'job_title',
  'start_date',
  'contract_type',
  'birth_date',
  'legal_sex',
  'marital_status',
  'personal_email',
  'phone_country_code',
  'phone_number_short',
  'address_line_1',
  'address_line_2',
  'address_line_3',
  'bank_name',
  'account_name',
  'bank_currency',
  'emergency_contact_email',
  'emergency_contact_full_name',
  'emergency_contact_relationship',
  'emergency_contact_mobile_phone',
  'emergency_contact_phone_country_code',
  'emergency_contact_phone_number',
  'contract_term',
  'end_date',
  'pay_group',
  'salary_currency',
  'salary_amount',
  'salary_time_unit',
  'salary_payment_frequency',
  'sign_on_bonus_type',
  'sign_on_bonus_amount',
  'sign_on_bonus_currency',
  'weekly_working_hours',
  'full_time_equivalent',
  'link_to_justification',
  'notice_period_during_probation',
  'notice_period_during_probation_unit',
  'notice_period_after_probation',
  'notice_period_after_probation_unit',
  'work_modality',
  'changes_effective_date',
  'compensation_backdate_correction_type',
  'status',
])

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  location: ['location'],
  line_manager: ['line_manager'],
}
