import React from 'react'
import {
  AvatarSize,
  AvatarSkeleton,
  Box,
  HStack,
  ProgressCircle,
  Separator,
  Spacer,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import { useGetSurveyAnalytics } from '@src/features/Engagement/api/analytics'
import { TrendIndicator } from '@src/features/Engagement/components/TrendIndicator'
import {
  normalizedScoreToColors,
  participationRateToColor,
} from '@src/features/Engagement/helpers/statValuesToColor'
import { FilterByInterface } from '@src/interfaces/data'
import { formatPercentage } from '@src/utils/format'
import { toIdAndName } from '@src/utils/toIdAndName'
import { RoundActions } from '../Rounds/RoundActions'
import { RoundSelector } from '../Rounds/Selector'
import { UseSurveyRoundSelectorReturnType } from '../Rounds/Selector/hooks/useSurveyRoundSelector'
import { TimeSeriesPreview } from './TimeSeriesPreview'

interface Props {
  roundSelectorData: UseSurveyRoundSelectorReturnType
  isNestedRoundSelector?: boolean
  scopeFilters?: FilterByInterface[]
}
export const SurveySubheader = ({
  roundSelectorData,
  isNestedRoundSelector,
  scopeFilters,
}: Props) => {
  const selectedSurveyId = roundSelectorData.survey.value?.id

  const dateFilters =
    roundSelectorData.dateFrom && roundSelectorData.dateTo
      ? [
          { columnName: 'from_date', filters: [toIdAndName(roundSelectorData.dateFrom)] },
          { columnName: 'to_date', filters: [toIdAndName(roundSelectorData.dateTo)] },
        ]
      : []
  const { data: surveyAnalytics, isLoading: isLoadingSurveyAnalytics } =
    useGetSurveyAnalytics({
      surveyId: selectedSurveyId,
      surveyRoundId: roundSelectorData.round.value?.id,
      filters: [...dateFilters, ...(scopeFilters || [])],
    })
  const averageScore = surveyAnalytics?.average_score
  const averageScoreNormalized = typeof averageScore === 'number' ? averageScore / 10 : 0

  const responseRate = surveyAnalytics?.response_rate
  const participationRate = formatPercentage(responseRate ?? null)
  const participationRateColor =
    typeof responseRate !== 'number'
      ? Token.color.greyTone20
      : participationRateToColor(Math.floor(responseRate * 100))

  const isScopedView = !!scopeFilters?.length
  const { data: companySurveyAnalytics, isLoading: isLoadingCompanySurveyAnalytics } =
    useGetSurveyAnalytics({
      surveyId: selectedSurveyId,
      enabled: isScopedView,
      surveyRoundId: undefined,
    })

  const companyAverageScore = companySurveyAnalytics?.average_score
  const companyAverageScoreNormalized =
    typeof companyAverageScore === 'number' ? companyAverageScore / 10 : 0

  return (
    <Widget p="s-16" width="100%">
      <HStack space="s-24" align="center" minHeight={88}>
        <RoundSelector
          data={roundSelectorData}
          nested={isNestedRoundSelector}
          renderActions={() => {
            if (!selectedSurveyId || !roundSelectorData.round.value) {
              return null
            }
            return (
              <RoundActions
                surveyId={selectedSurveyId}
                round={roundSelectorData.round.value}
                refetch={roundSelectorData.refetchRounds}
                permissions={roundSelectorData.survey.data?.field_options.permissions}
                placeholder={<Spacer width={40} />}
              />
            )
          }}
        />
        <HStack align="center" space="s-12">
          {isLoadingSurveyAnalytics ? (
            <Box p={14}>
              <AvatarSkeleton size={AvatarSize.S_60} />
            </Box>
          ) : (
            <ProgressCircle
              variant="semicircle"
              strokeWidth={8}
              size="large"
              value={averageScoreNormalized}
              color={normalizedScoreToColors(averageScore)?.color}
            >
              <ProgressCircle.Text variant="heading2">
                {surveyAnalytics?.average_score || 'N/A'}
              </ProgressCircle.Text>
            </ProgressCircle>
          )}
          {averageScore != null && surveyAnalytics?.trend_average_score != null ? (
            <VStack>
              <HStack gap="s-8" align="center">
                <TrendIndicator
                  avgScore={averageScore}
                  trendAvgScore={surveyAnalytics?.trend_average_score}
                  variant="sm"
                />
                <TimeSeriesPreview surveyId={selectedSurveyId} />
              </HStack>
              <Text variant="body2">Avg score</Text>
            </VStack>
          ) : (
            <HStack align="center" space="s-8">
              <Text variant="body2">Avg score</Text>
              <TimeSeriesPreview surveyId={selectedSurveyId} />
            </HStack>
          )}
        </HStack>
        <Box display={{ all: 'none', xl: 'initial' }} ml="s-24">
          <VStack space="s-8">
            <Text variant="emphasis2" color={Token.color.greyTone50}>
              Compare
            </Text>
            <HStack align="center" space="s-12">
              {isLoadingCompanySurveyAnalytics ? (
                <Box p={7}>
                  <AvatarSkeleton size={AvatarSize.S_36} />
                </Box>
              ) : (
                <ProgressCircle
                  variant="semicircle"
                  strokeWidth={4}
                  size="small"
                  value={companyAverageScoreNormalized}
                  color={normalizedScoreToColors(companyAverageScore)?.color}
                >
                  <ProgressCircle.Text>
                    {companySurveyAnalytics?.average_score || 'N/A'}
                  </ProgressCircle.Text>
                </ProgressCircle>
              )}
              <Text variant="emphasis1">Company avg.</Text>
            </HStack>
          </VStack>
        </Box>
        <Box height="s-48">
          <Separator orientation="vertical" />
        </Box>
        <VStack space="s-8">
          <Text variant="emphasis2" color={Token.color.greyTone50}>
            Involvement
          </Text>
          <HStack align="center" space="s-12">
            {isLoadingSurveyAnalytics ? (
              <Box p={7}>
                <AvatarSkeleton size={AvatarSize.S_36} />
              </Box>
            ) : (
              <ProgressCircle
                variant="semicircle"
                strokeWidth={4}
                size="small"
                value={surveyAnalytics?.response_rate}
                color={participationRateColor}
              >
                <ProgressCircle.Text>{participationRate}</ProgressCircle.Text>
              </ProgressCircle>
            )}
            <Text variant="emphasis1">Participation</Text>
          </HStack>
        </VStack>
      </HStack>
    </Widget>
  )
}
