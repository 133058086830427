import { AxiosPromise } from 'axios'
import { apiV2 } from '@src/api/index'
import { getCommentsAPI } from '@src/api/comments'
import { API } from '@src/constants/api'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
  ExportRequest,
} from '@src/interfaces'
import {
  CommitteeResultInterface,
  ProbationCheckpoints,
  ProbationGoalInterface,
  ProbationManagersGrade,
} from '@src/interfaces/probationReview'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import {
  CycleDetailInterface,
  EmployeePipInterface,
  PipFormInterface,
} from '@src/pages/Forms/PipV2/interfaces'
import { useDeleteV2, useFetch, useFetchV2, useUpdateV2 } from '@src/utils/reactQuery'
import { PipCycleSettingsInterface } from '@src/interfaces/reviewCycles'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  ComplianceStatsDTO,
  ProbationInterface,
  ProbationStatisticsDTO,
} from '@src/interfaces/probation'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'
import { DeliverableInterface } from '@src/interfaces/deliverables'

export const useGetEmployee = (employeeId: number | string) => {
  return useFetch<EmployeePipInterface>(`${API.PIP}/employee/${employeeId}`, 'v2')
}

export const pipRequestsNew: RequestInterfaceNew<PipFormInterface> = {
  get: async ({ id }) =>
    apiV2.get(`${API.PIP}/cycleDetail/${getReviewCycleIdWithoutPrefix(id)}`, {}, 'v2'),
  update: async (_, { employeeId, id }, data) =>
    apiV2.put(
      `${API.PIP}/${employeeId}/cycle/${getReviewCycleIdWithoutPrefix(id)}`,
      data,
      {},
      'v2',
    ),
  submit: async (data, { employeeId }) =>
    apiV2.post(`${API.PIP}/${employeeId}/cycle`, data, {}, 'v2'),
}

export const pipCycleRequestsNew: RequestInterfaceNew<CycleDetailInterface> = {
  get: async ({ id }) =>
    apiV2.get(`${API.PIP}/cycleDetail/${getReviewCycleIdWithoutPrefix(id)}`, {}, 'v2'),
  update: async (data, { id }) =>
    apiV2.put(
      `${API.PIP}/cycleGoals/${getReviewCycleIdWithoutPrefix(id)}`,
      data,
      {},
      'v2',
    ),
  submit: async (data, { id }) =>
    apiV2.put(
      `${API.PIP}/cycleGoals/${getReviewCycleIdWithoutPrefix(id)}`,
      data,
      {},
      'v2',
    ),
}

export const useGetPipCheckpoints = (
  employeeId: number | string | null,
  cycleId: number | string | null,
) => {
  return useFetch<ProbationCheckpoints>(
    employeeId && cycleId
      ? `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints`
      : null,
    'v2',
  )
}

export const getPipGoals = (employeeId: number | string, cycleId: number | string) =>
  apiV2.get<GetRequestInterface<ProbationGoalInterface>>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipGoals`,
    {},
    'v2',
  )

export const addPipGoals = (
  employeeId: number | string,
  data: ProbationGoalInterface,
  cycleId: number | string,
) =>
  apiV2.post<ProbationGoalInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipGoals`,
    data,
    {},
    'v2',
  )

export const deletePipGoal = (
  employeeId: number | string,
  goalId: number,
  cycleId: number | string,
) =>
  apiV2.delete(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipGoals/${goalId}`,
    {},
    'v2',
  )

export const approvePipGoals = (
  employeeId: number,
  checkpointId: number,
  cycleId: string,
) =>
  apiV2.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints/${checkpointId}/approve`,
    {},
    undefined,
    'v2',
  )

export const requestEditPipGoals = (
  employeeId: number,
  checkpointId: number,
  cycleId: string,
) =>
  apiV2.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints/${checkpointId}/request_goals_edits`,
    {},
    undefined,
    'v2',
  )

export const pipCommentsApi = (cycleId?: number | string) =>
  getCommentsAPI({
    baseUrl: `${API.PIP}/${getReviewCycleIdWithoutPrefix(cycleId)}/comments`,
    apiVersion: 'v2',
  })

export const pipReviewLayoutRequests: RequestInterfaceNew<ReviewScorecardInterface> = {
  get: async ({ id, employeeId }) => {
    return apiV2.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/performanceReviews/${id}`,
      {
        params: {
          category: ReviewCategory.PIP_V2,
        },
      },
      'v2',
    )
  },
  update: async (data, { id }) =>
    apiV2.put(
      `${API.EMPLOYEES}/${data.reviewed_employee!.id}${
        API.PERFORMANCE
      }/performanceReviews/${id}`,
      data,
      {},
      'v2',
    ),
  submit: async data =>
    apiV2.post(
      `${API.EMPLOYEES}/${data.reviewed_employee?.id}${API.PERFORMANCE}/cycles/${data.cycle?.id}/performanceReviews`,
      {
        category: ReviewCategory.PIP_V2,
      },
      {},
      'v2',
    ),
}

export const committeeResultRequests: RequestInterfaceNew<CommitteeResultInterface> = {
  get: async ({ cycleId, employeeId, id }) =>
    apiV2.get(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions/${id}`,
      {},
      'v2',
    ),
  update: async (_, { employeeId, id, cycleId }, data) =>
    apiV2.put(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions/${id}`,
      data,
      {},
      'v2',
    ),
  submit: async (data, { employeeId, cycleId }) =>
    apiV2.post(
      `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions`,
      data,
      {},
      'v2',
    ),
}

export const requestEditScorecard = (
  employeeId: number | string,
  cycleId: number | string,
  scorecardId: number | string,
  commentValue: string,
) => {
  return apiV2.put(
    `${API.PIP}/${employeeId}/cycles/${cycleId}/scorecards/${scorecardId}/request_edits`,
    { comment: commentValue },
    undefined,
    'v2',
  )
}

export const approveScorecard = (
  employeeId: number | string,
  cycleId: number | string,
  scorecardId: number | string,
) => {
  return apiV2.put(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipCheckpoints/${scorecardId}/approve_managers_feedback`,
    undefined,
    undefined,
    'v2',
  )
}
export const getPipManagersGrade = (
  employeeId: number,
  cycleId: string,
): AxiosPromise<ProbationManagersGrade> =>
  apiV2.get(
    `${API.EMPLOYEES}/${employeeId}${API.PERFORMANCE}/cycles/${cycleId}/pipDecisions/calculatedManagersGrade`,
    {},
    'v2',
  )

export const pipCycleSettingsRequests: RequestInterfaceNew<PipCycleSettingsInterface> = {
  get: async ({ id }) => {
    return apiV2.get(`${API.PIP}/cycleDetail/${id}`, {}, 'v2')
  },
  update: async (data, { employeeId, id }) => {
    return apiV2.put(`${API.PIP}/${employeeId}/cycle/${id}`, data, {}, 'v2')
  },
  submit: async (data, { employeeId, id }) => {
    return apiV2.post(`${API.PIP}/${employeeId}/cycle/${id}`, data, {}, 'v2')
  },
}

export const getPipItems = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ProbationInterface>> =>
  apiV2.get(
    API.PIP_ITEMS,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const useGetPipStats = () =>
  useFetchV2<ProbationStatisticsDTO>({ url: API.PIP_STATS, version: 'v2' })

export const useGetPipComplianceStats = ({ enabled }: { enabled?: boolean } = {}) =>
  useFetchV2<ComplianceStatsDTO>({
    url: '/pip/all/complianceStats',
    version: 'v2',
    queryOptions: { enabled },
  })

export const useUpdatePipGoalV2 = (employeeId: string | number) =>
  useUpdateV2<DeliverableInterface, Partial<DeliverableInterface>>({
    url: `${API.EMPLOYEES}/${employeeId}/performance/goals`,
    version: 'v2',
  })

export const useDeletePipGoalV2 = (employeeId: string | number) =>
  useDeleteV2({
    url: `${API.EMPLOYEES}/${employeeId}/performance/goals`,
    version: 'v2',
  })

export const pipGoalsTableRequests = (
  employeeId: string | number,
): TableRequestInterface<DeliverableInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    apiV2.get<GetRequestData<DeliverableInterface>>(
      `${API.EMPLOYEES}/${employeeId}/performance/goals`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})
export const getPipItemsExport: ExportRequest = (exportType, filterQuery) =>
  apiV2.get(
    `${API.PIP_ITEMS}/${exportType}`,
    { params: filterQuery, responseType: 'blob' },
    'v2',
  )

export const regeneratePipScorecards = (cycleId: string) =>
  apiV2.post(`${API.PIP}/scorecards/${cycleId}/regenerate`, {}, undefined, 'v2')

export const getPipSmartGoalsRequests = (
  employeeId: string | number,
): RequestInterfaceNew<DeliverableInterface> => {
  return {
    get: async ({ id }) =>
      apiV2.get(
        `${API.EMPLOYEES}/${employeeId}/performance/goals/${id}`,
        undefined,
        'v2',
      ),
    update: async (data, { id }) =>
      apiV2.patch(
        `${API.EMPLOYEES}/${employeeId}/performance/goals/${id}`,
        data,
        undefined,
        'v2',
      ),
    submit: async data =>
      apiV2.post(
        `${API.EMPLOYEES}/${employeeId}/performance/goals`,
        data,
        undefined,
        'v2',
      ),
    delete: async ({ id }) =>
      apiV2.delete(
        `${API.EMPLOYEES}/${employeeId}/performance/goals/${id}`,
        undefined,
        'v2',
      ),
  }
}
