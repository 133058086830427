import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, InputGroup } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { testNotificationSqlQuery } from '@src/api/notificationTemplate'
import {
  DeliveryType,
  NotificationTemplateInterface,
  NotificationType,
  TemplateType,
} from '@src/interfaces/notificationTemplate'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { selectUser, selectFeatureFlags } from '@src/store/auth/selectors'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { getScheduleMessage, useCanSendToTenants } from './common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { SQLQuery } from '@src/features/AudienceSelection/SQLQuery'
import { AudienceTable } from '@src/features/AudienceSelection/AudienceTable'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import ScheduleWidget from '@src/features/ScheduleWidget/ScheduleWidget'
import TemplateTypeFields from '@src/pages/Forms/NotificationTemplate/components/TemplateTypeFields/TemplateTypeFields'
import SystemNotificationContent from '@src/pages/Forms/NotificationTemplate/components/SystemNotificationContent/SystemNotificationContent'
import AnnouncementContent from '@src/pages/Forms/NotificationTemplate/components/AnnouncementContent/AnnouncementContent'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import CommunicationGroupSelector from '@src/features/CommunicationGroupSelector/CommunicationGroupSelector'
import { FeatureFlags } from '@src/store/auth/types'
import BotSelect from '@src/pages/Forms/NotificationTemplate/components/BotSelect/BotSelect'
import { useTenantSelector } from '@src/api/tenants'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'

const General = () => {
  const user = useSelector(selectUser)

  const form = useLapeContext<NotificationTemplateInterface>()
  const { values, initialValues } = form

  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const isCommercialAndEmail = isCommercial && values.type?.id === NotificationType.email

  const canSendToTenants = useCanSendToTenants()
  const { data: tenants } = useTenantSelector([], canSendToTenants)

  useEffect(() => {
    if (!values.id) {
      values.delivery_type = isCommercialAndEmail
        ? { id: DeliveryType.event_based, name: 'Event based' }
        : { id: DeliveryType.ad_hoc, name: 'Ad hoc' }
    }
    if (isCommercialAndEmail) {
      handleDeliveryMethodChange()
    }
    if (!values.id && !values.owner) {
      values.owner = { id: user.id, name: user.full_name }
    }
    if (values.type.id === NotificationType.system) {
      values.template_type = { id: TemplateType.html, name: 'HTML' }
    }

    return () => {
      if (!values.id && form.values.template_type) {
        delete (form.values as Partial<NotificationTemplateInterface>).template_type
      }
    }
  }, [])

  useEffect(() => {
    if (values.is_for_all_tenants) {
      values.tenants_filters = null
    }
  }, [values.is_for_all_tenants])

  const handleDeliveryMethodChange = () => {
    values.use_new_schedule_method = values.delivery_type?.id === DeliveryType.scheduled
    if (values.delivery_type?.id === DeliveryType.event_based) {
      values.audience_selection_type = { id: 'none', name: 'None' }
    }
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Template details" />
          <InputGroup>
            <LapeNewInput name="name" label="Notification Name" required />
            <LapeRadioSelectInput
              name="owner"
              selector={selectorKeys.employee}
              label="Owner"
            />
            <LapeRadioSelectInput
              name="type"
              label="Notification type"
              selector={selectorKeys.templated_notification_types}
              disabled
            />
            <LapeNewTextArea name="description" label="Description" />
          </InputGroup>

          <NewStepperTitle title="When to send" />
          <InputGroup>
            <LapeRadioSelectInput
              name="delivery_type"
              label="When the notification should be sent?"
              selector={
                isCommercialAndEmail
                  ? selectorKeys.templated_notification_delivery_types_for_email
                  : selectorKeys.templated_notification_delivery_types
              }
              onAfterChange={handleDeliveryMethodChange}
            />
            {values.delivery_type?.id === DeliveryType.event_based ? (
              <LapeRadioSelectInput
                name="event_type"
                selector={selectorKeys.templated_notification_event_types}
                label="Event type"
              />
            ) : null}
            {values.delivery_type?.id === DeliveryType.one_time ? (
              <>
                <LapeNewDatePicker name="send_on_time" label="Date" required />
                <InputGroup variant="horizontal">
                  <LapeNewTimeInput name="send_on_time" label="Time" required />
                  <LapeRadioSelectInput
                    selector={selectorKeys.timezones}
                    label="Timezone"
                    name="send_on_timezone"
                  />
                </InputGroup>
              </>
            ) : null}
            {values.delivery_type?.id === DeliveryType.scheduled && (
              <>
                <HideIfCommercial>
                  <LapeNewSwitch
                    name="use_new_schedule_method"
                    itemTypeProps={{
                      title: 'Schedule using dropdowns',
                      description:
                        'Switch between cron schedule and manual schedule selection',
                    }}
                  />
                </HideIfCommercial>
                {values.use_new_schedule_method ? (
                  <ScheduleWidget />
                ) : (
                  <LapeNewInput
                    name="schedule"
                    label="Schedule"
                    message={getScheduleMessage(values)}
                    required
                  />
                )}
              </>
            )}
          </InputGroup>

          <NewStepperTitle title="Notification content" />
          <InputGroup>
            {values.type?.id === NotificationType.email && (
              <>
                <LapeNewInput name="email_subject" label="Subject" />
                <LapeNewInput name="email_send_from" label="Sender address" />
                <LapeNewInput name="email_send_as" label="Send as" />
                <LapeNewSwitch
                  name="email_allow_external_emails"
                  itemTypeProps={{ title: 'Allow personal emails' }}
                />
                <TemplateTypeFields />
              </>
            )}
            {values.type?.id === NotificationType.slack && (
              <>
                <LapeNewInput name="slack_send_as" label="Bot name" />
                <BotSelect />
                <LapeNewInput
                  name="slack_send_as_icon"
                  label="Bot Avatar"
                  message="A URL or a slack emoji, e.g. :revolut:"
                />
                <TemplateTypeFields />
              </>
            )}
            {values.type?.id === NotificationType.sms && (
              <>
                <LapeNewInput name="sms_send_from" label="Send from" />
                <TemplateTypeFields />
              </>
            )}
            {values.type?.id === NotificationType.system && <SystemNotificationContent />}
            {values.type?.id === NotificationType.announcement && <AnnouncementContent />}
          </InputGroup>

          <NewStepperTitle
            title="Audience"
            subtitle="Define who should receive this notification"
          />

          <Box mb="s-56">
            <InputGroup>
              {canSendToTenants ? (
                <>
                  <LapeNewSwitch
                    name="is_for_all_tenants"
                    itemTypeProps={{ title: 'Send to all tenants' }}
                  />
                  {values.is_for_all_tenants ? null : (
                    <LapeNewMultiSelect
                      options={
                        tenants?.options.map(opt => ({
                          id: opt.id,
                          label: opt.name,
                          value: opt,
                        })) || []
                      }
                      required
                      name="tenants_filters"
                      label="Tenants"
                      placeholder="Tenants"
                      /** The `onChange` and `value` need this custom transformation, because of how the API was implemented */
                      onChange={value => {
                        values.tenants_filters =
                          { id: value.map(opt => opt.value.id) } || null
                      }}
                      value={values.tenants_filters?.id
                        .map(id => {
                          const option = tenants?.options.find(opt => opt.id === id)
                          if (!option) {
                            return null
                          }
                          return { value: option, label: option?.name }
                        })
                        .filter(Boolean)}
                    />
                  )}
                </>
              ) : null}

              <LapeRadioSelectInput<
                NotificationTemplateInterface['audience_selection_type']
              >
                name="audience_selection_type"
                label="Selection mode"
                selector={
                  isCommercialAndEmail
                    ? selectorKeys.templated_notification_audience_selection_types_for_email
                    : selectorKeys.templated_notification_audience_selection_types
                }
                onAfterChange={mode => {
                  if (mode?.id === 'sql') {
                    values.table_filters = null
                  }
                  if (mode?.id === 'filtered_table') {
                    values.sql_query = null
                  }
                }}
                message={
                  values.delivery_type?.id === DeliveryType.event_based
                    ? 'The audience will be dynamically choosen based on the selected Event'
                    : ''
                }
                disabled={values.delivery_type?.id === DeliveryType.event_based}
              />
              <HideIfCommercial>
                {values.audience_selection_type?.id === 'sql' && (
                  <SQLQuery
                    type="notification-templates"
                    api={() => testNotificationSqlQuery(values)}
                    sqlQueryDbName={values.sql_query_db?.name}
                    queryFieldsToFormFields={{
                      sql_query: 'sql_query',
                      sql_query_db: 'sql_query_db',
                    }}
                  />
                )}
              </HideIfCommercial>
              {values.audience_selection_type?.id === 'filtered_table' && (
                <AudienceTable
                  filter={initialValues.table_filters}
                  onFilterChange={tableFilters => {
                    values.table_filters = tableFilters
                  }}
                />
              )}
              {values.audience_selection_type?.id === 'communication_groups' && (
                <Box>
                  <CommunicationGroupSelector
                    selectedGroups={values.communication_groups || []}
                    setSelectedGroups={audiences => {
                      values.communication_groups = audiences
                    }}
                  />
                </Box>
              )}
            </InputGroup>
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Notification saved successfully"
          previewUrl={ROUTES.FORMS.NOTIFICATION_TEMPLATE.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
