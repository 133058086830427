import { API } from '@src/constants/api'
import {
  BulkApproveGoalsPayloadInterface,
  BulkAssignHRManagerPayloadInterface,
} from '@src/interfaces/pipAndProbation'
import { useUpdateV2, usePostV2 } from '@src/utils/reactQuery'

export const useBulkAssignHRManager = () =>
  useUpdateV2<
    BulkAssignHRManagerPayloadInterface,
    Partial<BulkAssignHRManagerPayloadInterface>
  >({
    url: `${API.EMPLOYEE_PERFORMANCE_CYCLES_BULK_ASSIGN_MANAGER}`,
    version: 'v2',
    usePut: true,
    useId: false,
  })

export const useBulkApproveGoals = () =>
  usePostV2<BulkApproveGoalsPayloadInterface>({
    url: `${API.EMPLOYEE_PERFORMANCE_CYCLES_BULK_APPROVE_GOALS}`,
    version: 'v2',
  })
