import { AxiosError } from 'axios'

export const checkNonInputFieldsValidations = (
  error: AxiosError | undefined,
  fields: string[],
) => {
  if (!error || error.response?.status !== 400) {
    return false
  }
  return Object.keys(error.response.data || {}).some(field => fields.includes(field))
}
