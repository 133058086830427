import React from 'react'
import { PerformanceSelector } from '@src/interfaces/performance'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import {
  MoreBar,
  Switch,
  DetailsCell,
  Group,
  Widget,
  DetailsCellSkeleton,
  DetailsSkeleton,
} from '@revolut/ui-kit'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { pipCommentsApi, useDeletePipGoalV2, useUpdatePipGoalV2 } from '@src/api/pip'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import {
  getDefaultStatusVariant,
  StatusCell,
} from '@src/components/TableV2/Cells/StatusCell'
import { getSmartGoalStatus } from '../utils'

interface PipSmartGoalsDetailsViewOnlyProps {
  goal: DeliverableInterface
  pipCycle: PerformanceSelector
}

interface PipSmartGoalsDetailsProps extends PipSmartGoalsDetailsViewOnlyProps {
  isViewMode?: boolean
  employeeId: string | number
  onGoalDeleted: VoidFunction
  onGoalUpdated: (goal: DeliverableInterface) => void
  onEditClick: (goal: DeliverableInterface) => void
}

export const PipSmartGoalsDetails = ({
  isViewMode,
  employeeId,
  goal,
  pipCycle,
  onGoalUpdated,
  onGoalDeleted,
  onEditClick,
}: PipSmartGoalsDetailsProps) => {
  const confirmationDialog = useConfirmationDialog()
  const pipGoalUpdate = useUpdatePipGoalV2(employeeId)
  const pipGoalDelete = useDeletePipGoalV2(employeeId)

  const handleCompleteChange: React.ChangeEventHandler<
    HTMLInputElement
  > = async event => {
    const res = await pipGoalUpdate.mutateAsync([
      goal.id,
      { is_completed: event.target.checked },
    ])
    onGoalUpdated(res.data)
  }

  const handleDeleteClick: React.MouseEventHandler<HTMLButtonElement> = () =>
    confirmationDialog.show({
      label: 'Are you sure you want to delete goal?',
      noMessage: 'Cancel',
      yesMessage: 'Confirm',
      onConfirm: async () => {
        await pipGoalDelete.mutateAsync(goal.id)
        onGoalDeleted()
      },
    })

  if (isViewMode) {
    return <PipSmartGoalsDetailsViewOnly pipCycle={pipCycle} goal={goal} />
  }

  return (
    <>
      <MoreBar>
        <MoreBar.Action useIcon="Pencil" onClick={() => onEditClick(goal)}>
          Edit
        </MoreBar.Action>
        <MoreBar.Action useIcon="Delete" variant="negative" onClick={handleDeleteClick}>
          Delete
        </MoreBar.Action>
      </MoreBar>
      <Widget mt="s-16">
        <Group>
          {pipGoalUpdate.isLoading ? (
            <DetailsCellSkeleton>
              <DetailsSkeleton.Title />
              <DetailsSkeleton.Content />
            </DetailsCellSkeleton>
          ) : (
            <DetailsCell>
              <DetailsCell.Title alignSelf="center">Goal completed</DetailsCell.Title>
              <DetailsCell.Content>
                <Switch checked={goal.is_completed} onChange={handleCompleteChange} />
              </DetailsCell.Content>
            </DetailsCell>
          )}
        </Group>
      </Widget>
      <Widget mt="s-16">
        <FormPreview data={pipCycle}>
          <FormPreview.Item field="hr_manager" title="HR manager" type="employee" />
        </FormPreview>
        <FormPreview data={goal}>
          <Group>
            <FormPreview.Item field="due_date" title="Due date" type="date" />
            <FormPreview.Item field="name" title="SMART goal title" />
            <FormPreview.Item
              field="description"
              title="How will this goal be measured"
            />
          </Group>
        </FormPreview>
      </Widget>
      <CommentsSection api={pipCommentsApi(pipCycle.id)} />
    </>
  )
}

export const PipSmartGoalsDetailsViewOnly = ({
  goal,
  pipCycle,
}: PipSmartGoalsDetailsViewOnlyProps) => {
  const status = getSmartGoalStatus(goal)
  return (
    <>
      <Widget mt="s-16">
        <Group>
          <DetailsCell>
            <DetailsCell.Title alignSelf="center">Goal completed</DetailsCell.Title>
            <DetailsCell.Content>
              <StatusCell status={status} variant={getDefaultStatusVariant(status)} />
            </DetailsCell.Content>
          </DetailsCell>
        </Group>
      </Widget>
      <Widget mt="s-16">
        <FormPreview data={pipCycle}>
          <Group>
            <FormPreview.Item field="hr_manager" title="HR manager" type="employee" />
          </Group>
        </FormPreview>
        <FormPreview data={goal}>
          <Group>
            <FormPreview.Item field="deadline_date_time" title="Due date" type="date" />
            <FormPreview.Item field="name" title="SMART goal title" />
            <FormPreview.Item
              field="description"
              title="How will this goal be measured"
            />
          </Group>
        </FormPreview>
      </Widget>
      <CommentsSection api={pipCommentsApi(pipCycle.id)} />
    </>
  )
}
