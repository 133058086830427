import React, { useMemo } from 'react'
import {
  Widget,
  Box,
  Subheader,
  Item,
  Avatar,
  Text,
  ProgressSteps,
  ProgressStep,
  VStack,
  textChain,
} from '@revolut/ui-kit'
import { ResumeWorkExperience } from '@src/interfaces/careers'
import { ExpandableText } from './ExpandableText'
import {
  formatDateRange,
  calcDuration,
  calcDurationInYears,
  getDate,
} from '@src/pages/Forms/CVScreening/utils'

interface WorkExperienceProps {
  experiences: ResumeWorkExperience[]
}

type GroupedWorkExperience = {
  companyName: string
  startDate: string | null
  endDate: string | null
  records: ResumeWorkExperience[]
}

export const WorkExperience = ({ experiences }: WorkExperienceProps) => {
  const groupedExperiences = useMemo(() => {
    const groupedRecords: GroupedWorkExperience[] = []
    let currentGroup: GroupedWorkExperience | null = null

    for (let i = 0; i < experiences.length; i++) {
      const record = experiences[i]
      const companyName = record.company ? record.company.name : record.other_company_name

      if (currentGroup && currentGroup.companyName === companyName) {
        currentGroup.records.push(record)
        const currentStartDate = getDate(currentGroup.startDate)
        const startDate = getDate(record.start_date)
        const currentEndDate = getDate(currentGroup.endDate)
        const endDate = getDate(record.end_date)

        if (
          (currentStartDate &&
            startDate &&
            currentStartDate.getTime() > startDate.getTime()) ||
          (!currentStartDate && startDate)
        ) {
          currentGroup.startDate = record.start_date
        }
        if (
          (currentEndDate && endDate && endDate.getTime() > currentEndDate.getTime()) ||
          (!currentEndDate && endDate)
        ) {
          currentGroup.endDate = record.end_date
        }
      } else {
        currentGroup = {
          companyName,
          startDate: record.start_date,
          endDate: record.end_date,
          records: [record],
        }
        groupedRecords.push(currentGroup)
      }
    }

    return groupedRecords
  }, [experiences])

  return (
    <Widget>
      <Box px="s-16">
        <Subheader variant="nested">
          <Subheader.Title>Work experience</Subheader.Title>
        </Subheader>
      </Box>
      {groupedExperiences.map((groupedExperience, groupId) => (
        <Box key={groupId}>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="CompanyOutline" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{groupedExperience.companyName}</Item.Title>
              {groupedExperience.startDate ? (
                <Item.Description>
                  {calcDurationInYears(
                    groupedExperience.startDate,
                    groupedExperience.endDate || new Date(),
                  )}
                </Item.Description>
              ) : null}
            </Item.Content>
          </Item>
          <Box pl="s-16">
            <ProgressSteps variant="vertical-compact">
              {groupedExperience.records.map((experience, expId) => {
                const isPresent = experience.start_date && !experience.end_date
                return (
                  <ProgressStep state={isPresent ? 'pending' : 'done'} key={expId}>
                    <ProgressStep.Title>
                      {experience.position?.name || experience.other_position_title}
                    </ProgressStep.Title>
                    <ProgressStep.Description>
                      <VStack>
                        <Text>
                          {textChain(
                            `${formatDateRange(
                              experience.start_date,
                              experience.end_date,
                              'MMM yyyy',
                            )}${isPresent ? ' - Present' : ''}`,
                            calcDuration(
                              experience.start_date,
                              isPresent ? new Date() : experience.end_date,
                            ),
                          )}
                        </Text>
                        {experience.description && experience.description.length > 0 ? (
                          <ExpandableText>
                            {experience.description?.join(' ')}
                          </ExpandableText>
                        ) : null}
                      </VStack>
                    </ProgressStep.Description>
                  </ProgressStep>
                )
              })}
            </ProgressSteps>
          </Box>
        </Box>
      ))}
    </Widget>
  )
}
