import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useHasGlobalPermission } from '@src/hooks/useHasGlobalPermissions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { checkCanRequestFeedback } from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { PermissionTypes } from '@src/store/auth/types'

interface Props {
  employee: EmployeeInterface
}

export const useGetPermissions = ({ employee }: Props) => {
  const { data: performanceSettings } = useGetPerformanceSettings()
  const canDeleteEmployeePerformanceCycle = useHasGlobalPermission(
    PermissionTypes.DeleteEmployeePerformanceCycle,
  )

  const permissions = {
    canRequestReview:
      performanceSettings?.enable_peer_reviews ?? checkCanRequestFeedback(employee),
    canViewUpwards: performanceSettings?.enable_upwards_reviews,
    canDeleteEmployeePerformanceCycle,
    hasProbationCommitteeHRPermissions: employee.field_options.permissions?.includes(
      PermissionTypes.ProbationCommitteeHRPermissions,
    ),
  }

  return permissions
}
