import React, { useState } from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { PerformanceSelector } from '@src/interfaces/performance'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { smartGoalBaseRow, useSmartGoalsTable } from './PipSmartGoalsTable'
import { PipSmartGoalSideBar } from './PipSmartGoalSideBar'
import { PipSmartGoalsDetailsViewOnly } from './PipSmartGoalsDetails'
import { TableNames } from '@src/constants/table'
import { TableTypes } from '@src/interfaces/table'

interface Props {
  employeeId: string
  pipCycle: PerformanceSelector
}

export const PipSmartGoalsTableViewWidget = ({ employeeId, pipCycle }: Props) => {
  const table = useSmartGoalsTable(employeeId)
  const [selectedGoal, setSelectedGoal] = useState<DeliverableInterface | undefined>()

  return (
    <>
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable<DeliverableInterface>
            name={TableNames.PipReviewGoals}
            type={TableTypes.Contained}
            dataType="Goal"
            row={smartGoalBaseRow}
            onRowClick={(goal: DeliverableInterface) => setSelectedGoal(goal)}
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>

      {!!selectedGoal && (
        <PipSmartGoalSideBar onClose={() => setSelectedGoal(undefined)} variant="details">
          <PipSmartGoalsDetailsViewOnly goal={selectedGoal} pipCycle={pipCycle} />
        </PipSmartGoalSideBar>
      )}
    </>
  )
}
