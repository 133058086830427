import React, { useMemo, useState } from 'react'

import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { DepartmentResourcesStatsNavigation } from '@src/pages/Forms/DepartmentForm/Resources/components/StatsNavigation'
import { MoreBar } from '@revolut/ui-kit'
import { getTabResources } from '../api'
import { DepartmentInterface } from '@src/interfaces/deparment'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { TableNames } from '@src/constants/table'
import {
  departmentResourcesResourceActionColumn,
  departmentResourcesResourceDescriptionColumn,
  departmentResourcesResourceNameColumn,
  departmentResourcesResourceOwnerColumn,
  departmentResourcesResourceTypeColumn,
} from '../constants/columns'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { navigateToDepartmentResourcePath } from '../helpers'
import { PermissionTypes } from '@src/store/auth/types'
import { toIdAndName } from '@src/utils/toIdAndName'

interface DepartmentResourcesGeneralTabProps {
  data: DepartmentInterface
  tabId: number
  sectionId?: number
}

export const DepartmentResourcesGeneralTab = ({
  data,
  tabId,
  sectionId,
}: DepartmentResourcesGeneralTabProps) => {
  const [manageEnabled, setManageEnabled] = useState(false)

  const canManageResources = !!data.field_options?.permissions?.includes(
    PermissionTypes.CanManageResources,
  )

  const table = useTable(
    { getItems: getTabResources({ tabId, departmentId: data.id }) },
    sectionId
      ? [
          {
            filters: [toIdAndName(`${sectionId}`)],
            columnName: 'section_id',
            nonResettable: true,
            nonInheritable: true,
          },
        ]
      : undefined,
  )

  const row = useMemo(() => {
    return {
      cells: [
        {
          ...departmentResourcesResourceNameColumn({
            departmentId: data.id,
            tabId,
            canManageResources,
          }),
          width: 130,
        },
        {
          ...departmentResourcesResourceTypeColumn,
          width: 100,
        },
        {
          ...departmentResourcesResourceOwnerColumn,
          width: 100,
        },
        {
          ...departmentResourcesResourceDescriptionColumn,
          width: 150,
        },
        {
          ...departmentResourcesResourceActionColumn({
            departmentId: data.id,
            tabId,
            canManageResources,
          }),
          width: 40,
        },
      ],
    }
  }, [data.id, tabId])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <DepartmentResourcesStatsNavigation tabId={tabId} departmentId={data.id} />
      </Table.Widget.Info>

      {canManageResources && (
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <PrimaryAction
              onClick={() =>
                navigateToDepartmentResourcePath({
                  departmentId: data.id,
                  tabId,
                  initialSection: sectionId,
                })
              }
              useIcon="Plus"
            >
              Add resource
            </PrimaryAction>

            <MoreBar.Action
              useIcon={manageEnabled ? 'SwitchOn' : 'SwitchOff'}
              onClick={() => setManageEnabled(prev => !prev)}
            >
              Manage
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
      )}

      <Table.Widget.Table>
        <AdjustableTable
          row={row}
          name={TableNames.DepartmentResources}
          {...table}
          emptyState={<EmptyTableRaw title="Resources will appear here" />}
          hiddenCells={{
            action: !manageEnabled || !canManageResources,
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
