import React, { useRef, useState } from 'react'

import { useQuery } from '@src/utils/queryParamsHooks'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CreateTeamPopup } from '@src/features/GenericEditableTable/CellPopups/CreateTeam'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { CreateEntityPopup } from '@src/features/GenericEditableTable/CellPopups/CreateEntity'
import { CreateDepartmentPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDepartment'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CreateLocationPopup } from '@src/features/GenericEditableTable/CellPopups/CreateLocation'
import {
  DYNAMIC_COLUMNS_QUERY_KEY,
  CUSTOM_FIELDS_QUERY_KEY,
} from '@src/features/GenericEditableTable/constants'
import { TEMPLATE_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'
import { IS_EMPLOYEE_UPDATE_QUERY_KEY } from './constants'
import { BulkEmployeeUploadFlowProps, CreateNewItemType } from '.'
import { useGetSectionCustomFields } from '@src/api/customFields'
import { SectionOptions } from '@src/interfaces/customFields'
import { IdAndName } from '@src/interfaces'
import { refreshBulkImportSessionValidation } from '@src/api/bulkDataImport'
import { useParams } from 'react-router-dom'

export const BulkEmployeeUploadFlow = ({
  getRow,
  hiddenColumns,
  getHeader,
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  onAfterConfirmRoute,
  renderMoreActions,
  headerTitle,
  visibleActions,
}: BulkEmployeeUploadFlowProps) => {
  const { query } = useQuery()
  const params = useParams<{ id: string }>()
  const tableRefresh = useRef<() => void>()

  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    onChangeAction: (entity: IdAndName) => void
  }>()

  const refreshValidation = async () => {
    await refreshBulkImportSessionValidation(apiEndpoint, params.id)
    tableRefresh.current?.()
  }

  const onCreateNew = (
    type: CreateNewItemType,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, onChangeAction })
  }

  const customFieldsIds = query[CUSTOM_FIELDS_QUERY_KEY] || ''
  const { data: customFieldsData } = useGetSectionCustomFields(
    customFieldsIds ? SectionOptions.EmployeeProfile : undefined,
    [],
    customFieldsIds.split(','),
  )
  const customFields = customFieldsData?.results || []

  return (
    <>
      <BulkDataUploadV2
        showDataOnFailure
        importRoute={importRoute}
        sessionRoute={sessionRoute}
        anyRoute={anyRoute}
        apiEndpoint={apiEndpoint}
        category="employee_upload"
        name="employee"
        tableName={TableNames.ImportEmployeesV2}
        row={getRow(onCreateNew, customFields)}
        entity="employee"
        templateParams={{
          version: '2',
          ...(query[IS_EMPLOYEE_UPDATE_QUERY_KEY]
            ? null
            : {
                extra_columns:
                  'first_name,last_name,seniority,team,specialisation,access_level',
              }),
        }}
        header={getHeader(headerTitle)}
        hiddenColumns={hiddenColumns}
        onAfterConfirmRoute={onAfterConfirmRoute}
        ignoreQueryParams={[
          DYNAMIC_COLUMNS_QUERY_KEY,
          CUSTOM_FIELDS_QUERY_KEY,
          TEMPLATE_TYPE_QUERY_KEY,
          IS_EMPLOYEE_UPDATE_QUERY_KEY,
        ]}
        localStorageKey={
          query[IS_EMPLOYEE_UPDATE_QUERY_KEY]
            ? LOCAL_STORAGE.ONBOARDING_EMPLOYEE_UPDATE_CONFIGURABLE_FIELDS_KEY
            : LOCAL_STORAGE.ONBOARDING_EMPLOYEE_CREATE_CONFIGURABLE_FIELDS_KEY
        }
        refreshTableHandler={cb => {
          tableRefresh.current = cb
        }}
        tableActions={tableActionsProps => (
          <>
            {visibleActions.team && (
              <BulkEditAction
                buttonIcon="Suitcase"
                field="team"
                selector={selectorKeys.team}
                onCreateNew={onChangeAction => {
                  setCreateNewTypeState({ type: 'team', onChangeAction })
                }}
                {...tableActionsProps}
              />
            )}
            {visibleActions.specialisation && (
              <BulkEditAction
                buttonIcon="RepairTool"
                field="specialisation"
                selector={selectorKeys.specialisations}
                {...tableActionsProps}
                onCreateNew={onChangeAction => {
                  setCreateNewTypeState({ type: 'role', onChangeAction })
                }}
                label="role"
              />
            )}
            {visibleActions.seniority && (
              <BulkEditAction
                buttonIcon="ArrowRightLeft"
                field="seniority"
                selector={selectorKeys.seniority}
                {...tableActionsProps}
              />
            )}

            {renderMoreActions?.(tableActionsProps)}
            <BulkDeleteButton {...tableActionsProps} />
          </>
        )}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={async team => {
          createNewTypeState?.onChangeAction(team)

          await refreshValidation()
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={async role => {
          createNewTypeState?.onChangeAction(role)

          await refreshValidation()
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateEntityPopup
        open={createNewTypeState?.type === 'companyEntity'}
        onSuccess={async entity => {
          createNewTypeState?.onChangeAction(entity)

          await refreshValidation()
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateLocationPopup
        open={createNewTypeState?.type === 'location'}
        onSuccess={async entity => {
          createNewTypeState?.onChangeAction(entity)

          await refreshValidation()
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
        showSuccessPopup={false}
      />

      <CreateDepartmentPopup
        open={createNewTypeState?.type === 'department'}
        onSuccess={async entity => {
          createNewTypeState?.onChangeAction(entity)

          await refreshValidation()
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}
