import React, { useMemo } from 'react'

import { navigateTo } from '@src/actions/RouterActions'
import { useGetSelectorsWithFilters } from '@src/api/selectors'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { selectorKeys } from '@src/constants/api'
import {
  getEngagementResultsScoreColumn,
  engagementResultsCommentsNumColumn,
  engagementResultsDistributionColumn,
  engagementResultsQuestionCategoryNameColumn,
  engagementResultsQuestionTypeColumn,
  getEngagementResultsQuestionNameColumn,
} from '@src/constants/columns/engagementResults'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { getEngagementQuestionsResultsTableRequests } from '@src/features/Engagement/api/analytics'
import {
  EngagementResultInterface,
  ItemsToAnalyse,
} from '@src/features/Engagement/api/analytics/interfaces'
import { useIsEngagementManager } from '@src/features/Engagement/hooks/permissions'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { toIdAndName } from '@src/utils/toIdAndName'

import { getDetailsPageQueryParams } from '../Details/common'
import { ResultsInterface } from './common'
import {
  getDateRangeFilterParams,
  getSurveyRoundFilterParams,
  useApplyNonTableFilters,
} from './hooks/useApplyNonTableFilters'

const getRow = ({
  surveyId,
  canViewDetails,
  queryParams,
  questionsOptions = [],
  isScopedView,
}: {
  surveyId: number
  canViewDetails: boolean
  queryParams: Record<string, string>
  questionsOptions: IdAndName[]
  isScopedView: boolean
}): RowInterface<EngagementResultInterface> => ({
  linkToForm: canViewDetails
    ? ({ id: questionId }) =>
        navigateTo(
          pathToUrl(
            ROUTES.PERFORMANCE.ENGAGEMENT.SURVEY_RESULTS.ITEM_DETAILS.QUESTION,
            { id: surveyId, itemId: questionId },
            queryParams,
          ),
        )
    : undefined,
  cells: [
    { ...getEngagementResultsQuestionNameColumn(questionsOptions), width: 350 },
    { ...getEngagementResultsScoreColumn({ isScopedView }), width: 120 },
    { ...engagementResultsDistributionColumn, width: 250 },
    { ...engagementResultsQuestionCategoryNameColumn, width: 100 },
    { ...engagementResultsQuestionTypeColumn, width: 120 },
    { ...engagementResultsCommentsNumColumn, width: 100 },
  ],
})

export const ResultQuestionsTable = ({
  survey,
  viewMode,
  timelineFilter,
  initialFilters = [],
  scopeFilters = [],
}: ResultsInterface) => {
  const isEngagementManager = useIsEngagementManager()

  const itemsToAnalyse: ItemsToAnalyse = 'questions'
  const disableTableRequests = viewMode !== 'table'

  const table = useTable<EngagementResultInterface>(
    getEngagementQuestionsResultsTableRequests(survey.id),
    [
      ...getSurveyRoundFilterParams(timelineFilter),
      ...getDateRangeFilterParams(
        timelineFilter.dateFrom,
        timelineFilter.dateTo,
        itemsToAnalyse,
      ),
      ...initialFilters,
      ...scopeFilters,
    ],
    undefined,
    {
      disable: disableTableRequests,
      disableQuery: true,
    },
  )

  useApplyNonTableFilters({
    disable: disableTableRequests || table.loading,
    table,
    timelineFilter,
    scopeFilters,
    itemsToAnalyse: 'questions',
  })

  const { data: questionsOptions = [] } = useGetSelectorsWithFilters<IdAndName>({
    type: selectorKeys.survey_questions,
    filters: [{ columnName: 'survey_id', filters: [toIdAndName(String(survey.id))] }],
  })
  const detailsPageQueryParams = getDetailsPageQueryParams(timelineFilter, scopeFilters)
  const row = useMemo(
    () =>
      getRow({
        surveyId: survey.id,
        canViewDetails: isEngagementManager,
        queryParams: detailsPageQueryParams,
        questionsOptions,
        isScopedView: !!scopeFilters?.length,
      }),
    [survey.id, isEngagementManager, detailsPageQueryParams, questionsOptions],
  )

  return (
    <>
      <AdjustableTable
        name={TableNames.EngagementSurveysResultQuestions}
        row={row}
        emptyState={<EmptyTableRaw title="Results not found" />}
        hideCount
        useWindowScroll
        {...table}
      />
    </>
  )
}
