import React, { useState, useMemo } from 'react'
import {
  VStack,
  TabBar,
  Token,
  TabBarSkeleton,
  Grid,
  Box,
  useMatchMedia,
  Button,
  Caption,
} from '@revolut/ui-kit'
import { customPlanConfiguration, plansConfig } from './config'
import { ROUTES } from '@src/constants/routes'
import { useSubscriptionPlanContextV2 } from './SubscriptionPlanProvider'
import { useAvailableSubscriptionPlansV2, useSubsciptionInfo } from '@src/api/plans'
import { pathToUrl } from '@src/utils/router'
import { useScrollToTop } from '@src/hooks/useScrollToTop'
import { PlanWidget } from '@src/pages/Forms/Plans/PlanWidget'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PlanType } from '@src/pages/Forms/Plans/types'
import { PageActions } from '@components/Page/PageActions'
import { AvailableSubscriptionPlanInterfaceV2 } from '@src/interfaces/plans'
import { SelectPlanButton } from '@src/pages/Forms/Plans/SelectPlanButton'
import { IdAndName } from '@src/interfaces'

const COMPANY_SIZES: IdAndName[] = [
  { id: 10, name: '25-49 employees' },
  { id: 50, name: '50-99 employees' },
  { id: 100, name: '100-249 employees' },
  { id: 250, name: '250-499 employees' },
  { id: 500, name: '500-999 employees' },
  { id: 1000, name: '1000+ employees' },
]

const COMPANY_SIZES_OPTIONS = COMPANY_SIZES.map(size => ({
  value: size,
  label: size.name,
}))

export const SelectPlanV2 = () => {
  useScrollToTop()

  const { plans } = useAvailableSubscriptionPlansV2()
  const { selectedPlan, setSelectedPlan } = useSubscriptionPlanContextV2()
  const [mobilePlan, setMobilePlan] = useState<AvailableSubscriptionPlanInterfaceV2>()
  const { info, isLoading: isInfoLoading } = useSubsciptionInfo()
  const isMd = useMatchMedia(Token.media.md)
  const [planType, setPlanType] = useState<PlanType>()
  const [companySize, setCompanySize] = useState<IdAndName>(COMPANY_SIZES[0])

  const availablePlans = useMemo(
    () =>
      plans?.filter(p =>
        plansConfig.find(planConfig => {
          return planConfig.id === p.slug
        }),
      ),
    [plans],
  )

  // For simplicity, we treat the old plans (standard and custom) as "custom" because this is how it
  // should be displayed for the new V2 plans (as standalone plan, similar to custom)
  const isOnCustomPlan =
    info && info.subscription_plan && !info.subscription_plan.is_standard

  const customPlan =
    isOnCustomPlan && info && info.subscription_plan
      ? {
          planDetails: {
            ...info.subscription_plan,
            description: '', // intentionally to hide this section in the PlanWidget
          },
          planConfig: customPlanConfiguration(info.subscription_plan),
        }
      : null
  const isOnMonthlyPlan = customPlan
    ? false
    : info &&
      info.subscription_plan &&
      'manual_payment' in info.subscription_plan &&
      info.subscription_plan.manual_payment === null
  const subscribedPlan = info?.subscription_plan
  const subscribedPlanId = info?.subscription_plan?.id
  const isCurrentPlanSelected = selectedPlan && subscribedPlanId === selectedPlan.id
  const noPaymentMethod = info?.revolut_merchant_api_payment_method === null

  if (subscribedPlanId && availablePlans) {
    setSelectedPlan(availablePlans.find(p => p.id === subscribedPlanId))
  }

  if (!planType && info) {
    setPlanType(isOnCustomPlan ? 'custom' : 'monthly')
  }

  if (
    !planType &&
    (isOnMonthlyPlan || isCurrentPlanSelected || (!isInfoLoading && !subscribedPlanId))
  ) {
    setPlanType('monthly')
  }

  if (!mobilePlan && availablePlans?.[0]) {
    setMobilePlan(availablePlans[0])
  }

  return (
    <PageWrapper fullWidth>
      <PageHeader
        title={subscribedPlan ? 'Manage your plan' : 'Find your perfect plan'}
        backUrl={pathToUrl(ROUTES.MAIN)}
      />
      <PageBody maxWidth={Token.breakpoint.xl}>
        <VStack gap="s-24">
          {!customPlan ? (
            <>
              <TabBar
                display={{ all: 'flex', md: 'none' }}
                value={String(mobilePlan?.id)}
                onChange={value => {
                  if (value) {
                    setMobilePlan(availablePlans?.find(plan => plan.id === Number(value)))
                  }
                }}
              >
                {availablePlans?.map(plan => (
                  <TabBar.Item to={String(plan.id)} key={plan.id}>
                    {plan.name}
                  </TabBar.Item>
                ))}
              </TabBar>

              <VStack space="s-16" align="center">
                {planType ? (
                  <TabBar
                    value={planType}
                    onChange={value => {
                      if (value) {
                        setPlanType(value)
                      }
                    }}
                    variant="segmented fit"
                    mx="auto"
                    display={{ all: 'none', md: 'flex' }}
                  >
                    <TabBar.Item to="monthly">Monthly</TabBar.Item>
                    <TabBar.Item to="custom">Annual</TabBar.Item>
                  </TabBar>
                ) : (
                  <TabBarSkeleton variant="segmented" width={300}>
                    <TabBarSkeleton.Item aria-selected />
                    <TabBarSkeleton.Item />
                  </TabBarSkeleton>
                )}

                {planType === 'custom' ? (
                  <Box
                    width={{ all: '100%', md: undefined }}
                    maxWidth={{ all: '100%', md: 460 }}
                  >
                    <RadioSelectInput
                      options={COMPANY_SIZES_OPTIONS}
                      label="How big is your company?"
                      onChange={val => {
                        if (val) {
                          setCompanySize(val)
                        }
                      }}
                      value={companySize}
                    />
                  </Box>
                ) : null}
              </VStack>

              <Grid columns={{ all: 1, md: 4 }} gap="s-24" data-testid="plans-widget">
                {planType &&
                  plansConfig.map(planConfig => {
                    const plan = plans?.find(p => p.slug === planConfig.id)
                    if (!plan || (!isMd && plan.id !== mobilePlan?.id)) {
                      return null
                    }

                    return (
                      <PlanWidget
                        plan={plan}
                        backgroundColor={
                          !isMd ? Token.color.widgetBackground : planConfig.bgColor
                        }
                        features={[planConfig]}
                        flatFeatures
                        planDescription={planConfig.planDescription}
                        discountedPrices={planConfig.discountedPrices}
                        planType={planType}
                        key={plan.id}
                        companySizeOption={COMPANY_SIZES.indexOf(companySize)}
                        billingCycle={'month'}
                      >
                        <SelectPlanButton
                          plan={plan}
                          currentPlanId={info?.subscription_plan?.id}
                          planType={planType}
                          noPaymentMethod={noPaymentMethod}
                        />
                      </PlanWidget>
                    )
                  })}
              </Grid>
            </>
          ) : (
            planType && (
              <Grid
                data-testid="plans-widget-custom"
                width={{ all: 1, lg: 1 / 2 }}
                m="auto"
              >
                <PlanWidget
                  plan={customPlan.planDetails}
                  backgroundColor={
                    !isMd ? Token.color.widgetBackground : customPlan.planConfig.bgColor
                  }
                  features={customPlan.planConfig.features}
                  flatFeatures={false}
                  planDescription={''}
                  discountedPrices={[]}
                  planType={planType}
                  key={customPlan.planDetails.id}
                  companySizeOption={COMPANY_SIZES.indexOf(companySize)}
                  billingCycle={
                    customPlan.planDetails.cycle_period_days
                      ? `${customPlan.planDetails.cycle_period_days} days`
                      : 'month'
                  }
                >
                  <SelectPlanButton
                    plan={customPlan.planDetails}
                    currentPlanId={undefined}
                    planType={planType}
                    noPaymentMethod={noPaymentMethod}
                  />
                </PlanWidget>
              </Grid>
            )
          )}

          <Caption color={Token.color.greyTone20} textAlign="center" use="div" pb="s-32">
            All prices exclude tax, which may be added as applicable.
          </Caption>
        </VStack>
      </PageBody>

      <PageActions display={{ all: 'flex', md: 'none' }}>
        <VStack gap="s-16" pb="s-32">
          {mobilePlan && planType ? (
            <SelectPlanButton
              plan={mobilePlan}
              currentPlanId={info?.subscription_plan?.id}
              planType={planType}
              noPaymentMethod={noPaymentMethod}
              variant="primary"
            />
          ) : null}

          {planType !== 'custom' && (
            <Button
              variant="secondary"
              onClick={() => setPlanType(planType === 'monthly' ? 'custom' : 'monthly')}
            >
              {planType === 'monthly' ? 'View annual plans' : 'View monthly plans'}
            </Button>
          )}
        </VStack>
      </PageActions>
    </PageWrapper>
  )
}
