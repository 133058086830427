import React, { useState } from 'react'
import {
  ActionButton,
  BottomSheet,
  Box,
  Button,
  Flex,
  Header,
  HStack,
  StatusPopup,
  Text,
  TextArea,
  TextSkeleton,
  Token,
  useStatusPopup,
  useToggle,
} from '@revolut/ui-kit'
import { usePostFeedbackRequest, useGetMyFeedbacks } from '@src/api/user'

interface RateSummaryProps {
  interviewId: number | string
}

const INTERVIEW_TRANSCRIPT = 'interviewtranscript'

export const RateSummary = ({ interviewId }: RateSummaryProps) => {
  const statusPopup = useStatusPopup()
  const [commentValue, setCommentValue] = useState<string | undefined>()
  const [openRating, toggleOpenRating] = useToggle()
  const { data, isLoading } = useGetMyFeedbacks([
    {
      filters: [
        {
          id: interviewId,
          name: String(interviewId),
        },
      ],
      columnName: 'object_id',
    },
    {
      filters: [
        {
          id: INTERVIEW_TRANSCRIPT,
          name: INTERVIEW_TRANSCRIPT,
        },
      ],
      columnName: 'object_type',
    },
  ])
  const { mutateAsync: postFeedbackRequest, isLoading: pending } =
    usePostFeedbackRequest()

  // We only need to know if summary was accurate and a comment if it wasn't
  // so if the summary was accurate we send 1, if it wasn't then we have
  // the comment as optional and send 0
  const handleSubmitFeedback = async (score: 0 | 1) => {
    try {
      await postFeedbackRequest({
        status: { id: 'completed' },
        score,
        text: commentValue,
        topic: { id: 'recruitment' },
        labels: [],
        object_type: INTERVIEW_TRANSCRIPT,
        object_id: interviewId,
      })
      statusPopup.show(
        <StatusPopup variant="success" onClose={() => toggleOpenRating.off()}>
          <StatusPopup.Title>Thank you</StatusPopup.Title>
          <StatusPopup.Description>
            Your feedback is helping us to improve
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>Feedback was not submitted</StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }

  if (isLoading) {
    return (
      <Box px="s-16">
        <TextSkeleton aria-label="Loading user feedback" />
      </Box>
    )
  }

  const hasFeedback = Boolean(data?.results?.length)
  if (hasFeedback) {
    return null
  }

  return (
    <>
      <Flex py="s-8" px="s-16" justifyContent="space-between" alignItems="center">
        <Text color={Token.color.greyTone50} variant="body1">
          Was this summary accurate?
        </Text>
        <HStack gap="s-8">
          <ActionButton
            aria-label="Summary was accurate"
            iconOnly
            size="xs"
            useIcon="SocialLike"
            onClick={() => {
              handleSubmitFeedback(1)
            }}
          />
          <ActionButton
            aria-label="Summary wasn't accurate"
            iconOnly
            size="xs"
            useIcon="SocialDislike"
            onClick={() => {
              toggleOpenRating.on()
            }}
          />
        </HStack>
      </Flex>
      <BottomSheet open={openRating} onClose={toggleOpenRating.off}>
        <Header>
          <Header.Title>Why you chose this rating?</Header.Title>
        </Header>
        <BottomSheet.Description>
          <TextArea
            label="Provide additional feedback (optional)"
            rows={2}
            value={commentValue}
            onChange={e => setCommentValue(e.currentTarget.value)}
          />
        </BottomSheet.Description>
        <BottomSheet.Actions horizontal>
          <Button
            aria-label="Submit ratting"
            elevated
            pending={pending}
            onClick={() => handleSubmitFeedback(0)}
          >
            Submit
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}
