import { apiV2 } from '@src/api/index'

import { API } from '@src/constants/api'
import { ExportRequest } from '@src/interfaces'

export const getProbationItemsExport: ExportRequest = (exportType, filterQuery) =>
  apiV2.get(
    `${API.PROBATION}/${exportType}`,
    { params: filterQuery, responseType: 'blob' },
    'v2',
  )
