import React, { useEffect } from 'react'
import {
  ActionButton,
  Avatar,
  BarSkeleton,
  Cell,
  Ellipsis,
  HStack,
  Skeleton,
  Spacer,
  Text,
  Token,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { NotesHeader } from '../NotesHeader'
import { EmptyTableRaw as EmptyState } from '@src/components/Table/EmptyTableRaw'
import { getContentItemsByRole } from '../../utils/getContentItemsByRole'
import { Attachments } from '../Attachments'
import { TalkingPointsSection } from '../TalkingPointsSection'
import { PersonalNotesSection } from '../PersonalNotesSection'
import { useMeetingNoteContext } from '../../context/meetingNoteContext'
import { MeetingsSummary } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/components/MeetingsSummary/MeetingsSummary'
import { PageBody } from '@src/components/Page/PageBody'
import { MeetingsList } from '../../MeetingsList'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetEmployeeMeeting, useGetMeetingsSummary } from '@src/api/meetings'
import { oneToOneModel } from '../../../models/oneToOneModel'
import { useReviewCycleData } from '../../hooks/useReviewCycleData'
import { FormattedMessage } from 'react-intl'
import { CompletedEventContent } from './CompletedEventContent'
import {
  CYCLE_ID_QUERY_PARAM_KEY,
  MEETING_EVENT_ID_QUERY_PARAM_KEY,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/Summary/constants'

export const MeetingNotePageBody = () => {
  const { employeeId, managerId, meetingId } =
    useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const { query, deleteQueryParam } = useQuery<{
    [CYCLE_ID_QUERY_PARAM_KEY]?: string
    [MEETING_EVENT_ID_QUERY_PARAM_KEY]?: string
  }>()
  const currentUser = useSelector(selectUser)
  const [isMeetingsSummarySelected, meetingsSummaryToggler] = useToggle()
  const {
    onMeetingChange,
    onPerformanceRatingChange,
    onTalkingPointsChange,
    onPersonalNotesChange,
    onCreateNotes,
    setSelectedEvent,
    setMeetingList,
    refetchMeetingNotesFeedback,
    updatingSection,
    meetingNotesDetails,
    selectedEvent,
    settings: {
      hasEditDisabled,
      isMeetingListAvailable,
      showCompletedMeetingSection,
      isEnabledAI,
      isNotesEmptyState,
      isNotesDetails,
    },
    loadingStates: { isLoadingCreateNotes, isLoadingMeetingNotesDetails },
  } = useMeetingNoteContext()

  const { data: oneToOneMeeting, isLoading: isLoadingEmployeeManagerMeeting } =
    useGetEmployeeMeeting(employeeId, meetingId)

  const canViewMeetingsSummary =
    !isLoadingEmployeeManagerMeeting &&
    oneToOneMeeting &&
    oneToOneModel.isMeetingManagedBy(currentUser, oneToOneMeeting)

  const { data: meetingsSummaries, isLoading: isMeetingsSummariesLoading } =
    useGetMeetingsSummary(Number(employeeId), query.cycle_id, canViewMeetingsSummary)

  const { isLoading: isReviewCyclesLoading, dateRanges } = useReviewCycleData({
    activeCycleId: query.cycle_id,
    employeeId: Number(employeeId),
    managerId: Number(managerId),
  })

  const talkingPoints =
    getContentItemsByRole(meetingNotesDetails, 'talking_points')?.content.content || ''

  const personalNotes = meetingNotesDetails
    ? meetingNotesDetails[
        Object.hasOwn(meetingNotesDetails, 'manager_personal_notes')
          ? 'manager_personal_notes'
          : 'report_personal_notes'
      ]
    : ''

  const meetingsSummary = meetingsSummaries?.results[0]
  const hasMeetingsSummaryContent =
    meetingsSummary && !oneToOneModel.isEmptyMeetingsSummaryContent(meetingsSummary)

  useEffect(() => {
    if (isMeetingsSummarySelected && query.noteId !== undefined) {
      meetingsSummaryToggler.off()
    }
  }, [isMeetingsSummarySelected, query.noteId])

  useEffect(() => {
    if (hasMeetingsSummaryContent && query.noteId === undefined) {
      meetingsSummaryToggler.on()
    }
  }, [hasMeetingsSummaryContent, query.noteId])

  return (
    <PageBody mt="s-4" maxWidthMd={Token.breakpoint.max}>
      <TwoColumnsLayout
        leftFlex={0}
        leftMinWidth={isMeetingListAvailable || hasMeetingsSummaryContent ? 270 : '100%'}
        left={
          <VStack space="s-2" data-testid="meetings_navigation">
            {hasMeetingsSummaryContent && (
              <Cell
                use="button"
                onClick={() => {
                  meetingsSummaryToggler.on()
                  setSelectedEvent(undefined)
                  deleteQueryParam(MEETING_EVENT_ID_QUERY_PARAM_KEY)
                }}
                aria-pressed={isMeetingsSummarySelected}
              >
                <HStack space="s-16" align="center">
                  <Avatar useIcon="Document">
                    {isMeetingsSummarySelected && <Avatar.Badge useIcon="Check" />}
                  </Avatar>
                  <Text variant="emphasis1" width="100%" use="p">
                    <FormattedMessage
                      id="common.summary.title"
                      defaultMessage="Summary"
                    />
                  </Text>
                  <Spacer />
                  <Text
                    variant="body1"
                    color={Token.color.greyTone50}
                    width="100%"
                    use="p"
                  >
                    <Ellipsis>{meetingsSummary.cycle.name}</Ellipsis>
                  </Text>
                </HStack>
              </Cell>
            )}
            {dateRanges.startDate && dateRanges.endDate && (
              <MeetingsList
                meeting={oneToOneMeeting}
                setMeetingList={setMeetingList}
                startDate={dateRanges.startDate}
                endDate={dateRanges.endDate}
                isLoading={
                  isReviewCyclesLoading ||
                  isLoadingEmployeeManagerMeeting ||
                  isMeetingsSummariesLoading
                }
                employeeId={Number(employeeId)}
                managerId={Number(managerId)}
                selectedEvent={selectedEvent}
                setSelectedEvent={onMeetingChange}
                hideEmpty={hasMeetingsSummaryContent}
                preselect={
                  !canViewMeetingsSummary ||
                  query.noteId !== undefined ||
                  (!isMeetingsSummariesLoading && !hasMeetingsSummaryContent)
                }
              />
            )}
          </VStack>
        }
        right={
          <>
            {isMeetingsSummarySelected && hasMeetingsSummaryContent && (
              <MeetingsSummary summary={meetingsSummary} managerId={Number(managerId)} />
            )}
            {!isMeetingsSummarySelected && (
              <>
                <Widget data-testid="meeting_details">
                  {selectedEvent && isLoadingMeetingNotesDetails && (
                    <VStack p="s-16" gap="s-16">
                      <BarSkeleton />
                      <Skeleton height={300} radius="widget" />
                      <Skeleton height={300} radius="widget" />
                    </VStack>
                  )}
                  {isNotesEmptyState && (
                    <EmptyState
                      title={
                        hasEditDisabled
                          ? "This meeting doesn't have notes"
                          : 'You don’t have meeting notes yet'
                      }
                      imageId="3D298"
                      description={
                        hasEditDisabled
                          ? 'No notes were recorded during this meeting'
                          : 'Record talking points and actions, and take personal notes'
                      }
                      action={
                        hasEditDisabled ? null : (
                          <ActionButton
                            useIcon="Plus"
                            onClick={onCreateNotes}
                            pending={isLoadingCreateNotes}
                            disabled={hasEditDisabled}
                          >
                            Create meeting notes
                          </ActionButton>
                        )
                      }
                    />
                  )}
                  {isNotesDetails && (
                    <VStack p="s-16" gap="s-16">
                      <NotesHeader
                        disabled={hasEditDisabled}
                        isLoadingPerformanceRating={
                          updatingSection === 'performance_rating'
                        }
                        refetchMeetingNotesFeedback={refetchMeetingNotesFeedback}
                        meetingId={selectedEvent?.id}
                        meetingNotes={meetingNotesDetails}
                        isLoading={isLoadingMeetingNotesDetails}
                        isCompletedEvent={showCompletedMeetingSection}
                        onPerformanceRatingChange={onPerformanceRatingChange}
                      />
                      <Text variant="heading3">{isEnabledAI ? 'AI Notes' : 'Notes'}</Text>
                      <TalkingPointsSection
                        disabled={hasEditDisabled}
                        id={meetingNotesDetails?.id}
                        isLoading={updatingSection === 'talking_points'}
                        talkingPoints={talkingPoints}
                        onTalkingPointsChange={onTalkingPointsChange}
                      />
                      <CompletedEventContent />
                      {meetingNotesDetails?.enable_personal_notes && (
                        <PersonalNotesSection
                          disabled={hasEditDisabled}
                          notes={personalNotes}
                          id={meetingNotesDetails?.id}
                          isLoading={updatingSection === 'personal_note'}
                          onPersonalNotesChange={onPersonalNotesChange}
                        />
                      )}
                    </VStack>
                  )}
                </Widget>
                <Attachments attachments={meetingNotesDetails?.attachments || []} />
              </>
            )}
          </>
        }
      />
    </PageBody>
  )
}
