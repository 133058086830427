import React, { useState, PropsWithChildren } from 'react'

import SideBar from '@src/components/SideBar/SideBar'
import { PipSmartGoalsDetails } from '@src/features/PIP/components/PipSmartGoalsDetails'
import {
  PipSmartGoalAddForm,
  PipSmartGoalEditForm,
} from '@src/features/PIP/components/PipSmartGoalForm'

import { DeliverableInterface } from '@src/interfaces/deliverables'
import { PerformanceSelector } from '@src/interfaces/performance'

type Variant = 'details' | 'edit' | 'add'

interface PipSmartGoalSideBarFormProps {
  variant: Variant
  employeeId: string | number
  goal: DeliverableInterface | undefined
  pipCycle: PerformanceSelector
  onGoalDeleted: VoidFunction
  onGoalUpdated: (goal: DeliverableInterface) => void
  onEditClick: (goal: DeliverableInterface) => void
}

const getTitleByVariant = (variant: Variant) => {
  switch (variant) {
    case 'details':
      return 'PIP goal'
    case 'edit':
      return 'Edit PIP goal'
    case 'add':
      return 'Add PIP goal'
    default:
      return ''
  }
}

interface PipSmartGoalSideBarProps {
  onClose: VoidFunction
  variant: Variant
}

export const PipSmartGoalSideBar = ({
  children,
  variant,
  onClose,
}: PropsWithChildren<PipSmartGoalSideBarProps>) => (
  <SideBar isOpen onClose={onClose} useBackButton title={getTitleByVariant(variant)}>
    {children}
  </SideBar>
)

export const useSideBarVariant = (intialVariant?: Variant) => {
  const [variant, setVariant] = useState<Variant | null>(intialVariant ?? null)

  return {
    variant,
    setEdit: () => setVariant('edit'),
    setAdd: () => setVariant('add'),
    setDetails: () => setVariant('details'),
    clear: () => setVariant(null),
  }
}

export const PipSmartGoalSideBarForm = ({
  employeeId,
  pipCycle,
  goal,
  variant = 'details',
  onEditClick,
  onGoalUpdated,
  onGoalDeleted,
  children: actions,
}: PropsWithChildren<PipSmartGoalSideBarFormProps>) => {
  if (goal && variant === 'details') {
    return (
      <PipSmartGoalsDetails
        goal={goal}
        pipCycle={pipCycle}
        employeeId={employeeId}
        onEditClick={onEditClick}
        onGoalDeleted={onGoalDeleted}
        onGoalUpdated={onGoalUpdated}
      />
    )
  }

  if (goal && variant === 'edit') {
    return (
      <PipSmartGoalEditForm goal={goal} employeeId={employeeId}>
        {actions}
      </PipSmartGoalEditForm>
    )
  }

  if (variant === 'add') {
    return (
      <PipSmartGoalAddForm employeeId={employeeId} pipCycle={pipCycle}>
        {actions}
      </PipSmartGoalAddForm>
    )
  }

  return null
}
