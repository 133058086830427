import React, { useState } from 'react'
import { Button, ButtonProps, useToggle, VStack } from '@revolut/ui-kit'
import { RoleInterface, RoleStep, SpecialisationInterface } from '@src/interfaces/roles'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import { arrayErrorsToFormError, difference } from '@src/utils/form'
import { useErrorPopup } from '@src/features/Errors/useErrorPopup'
import { useIsSpecialisationsEnabled } from '@src/features/Roles/hooks/useIsSpecialisationsEnabled'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { apiV2 } from '@src/api'
import { API } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import {
  getNormalizedCompetencyMatrix,
  getNormalizedFuncCompetencyMatrix,
} from '@src/features/CompetencyMatrixTable/utils'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import LapeSubmitPopups from '@src/features/Popups/LapeSubmitPopups'
import { FeatureFlags } from '@src/store/auth/types'
import { useHasFeatureFlag } from '@src/hooks/useHasFeatureFlag'
import { omitSpecialisationCompetencyMatrixV1Fields } from '@src/api/specialisations'

interface Props extends Omit<ButtonProps, 'onClick'> {
  onAfterSubmit: (data: SpecialisationInterface) => void
  type: 'specialisation' | 'role'
  step?: RoleStep
}

export const SubmitButton = ({ onAfterSubmit, type, children, step, ...rest }: Props) => {
  const formValidator = useFormValidator()
  const form = useLapeContext<RoleInterface | SpecialisationInterface>()
  const [isPending, setIsPending] = useState(false)
  const errorPopup = useErrorPopup()
  const showStatusPopup = useShowStatusPopup()
  const [showApprovalPopup, toggleShowApprovalPopup] = useToggle()
  const specialisationsEnabled = useIsSpecialisationsEnabled()
  const competencyMatrixV2 =
    useHasFeatureFlag(FeatureFlags.CompetencyMatrixV2) && type === 'specialisation'

  const showHiringProcessRoundErrors = (
    error: (FormError<HiringProcessInterface> | undefined)[],
  ) => {
    const idsWithError = Object.keys(error).map(Number)
    const hiringProcessRoundsErrors = form.values.hiring_process_rounds
      .filter(({ id }) => idsWithError.includes(id))
      .map(({ id, title }) => {
        const hiringStageRoute =
          type === 'role'
            ? ROUTES.FORMS.HIRING_STAGES.ROLE
            : ROUTES.FORMS.HIRING_STAGES.SPECIALISATION
        return (
          <InternalLink
            key={id}
            target="_blank"
            to={pathToUrl(hiringStageRoute, {
              id,
            })}
          >
            {title}
          </InternalLink>
        )
      })
    showStatusPopup({
      status: 'error',
      title: 'There are errors in the hiring stages',
      description: (
        <VStack>
          <VStack gap="s-8">{hiringProcessRoundsErrors}</VStack>
          Please edit these stages and try again
        </VStack>
      ),
    })
  }

  const submit = async () => {
    try {
      setIsPending(true)
      if (!form.values.id) {
        const data = {
          ...form.values,
          status: Statuses.draft,
          functional_competency_matrix: getNormalizedFuncCompetencyMatrix(form.values),
        }
        const response = await apiV2.post<SpecialisationInterface>(
          API.SPECIALISATIONS,
          competencyMatrixV2
            ? omitSpecialisationCompetencyMatrixV1Fields({
                ...data,
                competency_matrix: getNormalizedCompetencyMatrix(form.values),
              })
            : data,
        )
        if (response.data) {
          if (type === 'role') {
            workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {}))
          } else if (type === 'specialisation') {
            workspaceLocalStorage.removeItem(
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.EDIT.GENERAL_INFO, {}),
            )
          }
          onAfterSubmit(response.data)
        }
      } else {
        const diff = difference(form.values, form.initialValues) as
          | Partial<RoleInterface>
          | Partial<SpecialisationInterface>

        if (competencyMatrixV2) {
          if ('competency_matrix' in diff) {
            diff.competency_matrix = getNormalizedCompetencyMatrix(form.values)
          }
        } else {
          diff.functional_competency_matrix = getNormalizedFuncCompetencyMatrix(
            form.values,
          )
        }

        const response = await apiV2.patch(
          `${API.SPECIALISATIONS}/${form.values.id}`,
          competencyMatrixV2 ? omitSpecialisationCompetencyMatrixV1Fields(diff) : diff,
          { params: { step } },
        )
        if (response.data) {
          // if the last step and status did not change for auto approved show approval description popup
          if (step === 'hiring_process' && response.data.status === Statuses.draft) {
            toggleShowApprovalPopup.on()
          } else {
            onAfterSubmit(response.data)
          }
        }
      }
    } catch (error) {
      form.errors = arrayErrorsToFormError(error?.response?.data)
      if (error?.response?.data?.non_field_errors || error?.response?.data?.detail) {
        errorPopup.show({
          error,
          fallbackTitle: `Could not ${form.values.id ? 'update' : 'create'} ${
            specialisationsEnabled ? 'specialisation' : 'role'
          }`,
        })
      }
      if (form.errors.hiring_process_rounds) {
        showHiringProcessRoundErrors(form.errors.hiring_process_rounds)
      }
      if (!form.errors?.hiring_process_rounds) {
        throw error
      }
    } finally {
      setIsPending(false)
    }
  }

  const onSubmit = formValidator?.validate ? formValidator.validate(submit) : submit
  return (
    <>
      <Button {...rest} elevated onClick={onSubmit} pending={isPending}>
        {children}
      </Button>
      {showApprovalPopup && (
        <LapeSubmitPopups
          onClose={toggleShowApprovalPopup.off}
          onSubmit={() => {
            form.values.status = Statuses.pending
            try {
              onSubmit()
            } catch {
              form.values.status = form.initialValues?.status || Statuses.draft
            }
          }}
          type={specialisationsEnabled ? 'specialisation' : 'role'}
          loading={isPending}
        />
      )}
    </>
  )
}
