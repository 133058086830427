import { CellTypes, ColumnInterface, FilterType } from '../../interfaces/data'
import { selectorKeys } from '../api'
import { ReferralBonusInterface } from '@src/interfaces/referralBonus'
import { formatMoney } from '@src/utils/format'
import { getRoleSpecialisationTitle } from '@src/features/Roles/helpers'

export const referralBonusSpecialisationColumn = (
  specialisationsEnabled: boolean,
): ColumnInterface<ReferralBonusInterface> => ({
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: getRoleSpecialisationTitle(specialisationsEnabled),
})

export const referralBonusFunctionColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.role.function.id',
  dataPoint: 'specialisation.role.function.name',
  sortKey: 'specialisation__role__function__name',
  filterKey: 'specialisation__role__function__id',
  selectorsKey: selectorKeys.functions,
  title: 'Function',
}

export const referralBonusSeniorityColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const referralBonusLocationColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.name',
  sortKey: 'location__location_name',
  filterKey: 'location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const referralBonusTaxColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.text,
  idPoint: 'tax_type',
  dataPoint: 'tax_type',
  sortKey: 'tax_type',
  filterKey: 'tax_type',
  selectorsKey: selectorKeys.referral_bonuses_tax_type,
  title: 'Tax',
}

export const referralBonusCurrencyColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.text,
  idPoint: 'currency.id',
  dataPoint: 'currency.iso_code',
  sortKey: 'currency__iso_code',
  filterKey: 'currency__id',
  selectorsKey: selectorKeys.currencies,
  title: 'Currency',
}

export const referralBonusTypeColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.text,
  idPoint: 'bonus_type',
  dataPoint: 'bonus_type',
  sortKey: 'bonus_type',
  filterKey: 'bonus_type',
  selectorsKey: selectorKeys.referral_bonuses_bonus_type,
  title: 'Type',
}

export const referralBonusAmountColumn: ColumnInterface<ReferralBonusInterface> = {
  type: CellTypes.insert,
  idPoint: 'amount',
  dataPoint: 'amount',
  sortKey: 'amount',
  filterKey: 'amount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Amount',
  insert: ({ data }) =>
    Number.isFinite(data.amount) ? formatMoney(data.amount, data.currency.iso_code) : '-',
}
