import {
  ActionButton,
  Avatar,
  Flex,
  Group,
  Item,
  Tag,
  Text,
  Token,
} from '@revolut/ui-kit'
import {
  FinalGrade,
  FinalGradeInterface,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { CheckpointType, ProbationCheckpoint } from '@src/interfaces/probationReview'
import React from 'react'
import { CommitteeStepReviewers } from './CommitteeStepReviewers'
import { RecommendationStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/RecommendationStepReviewers'
import { CheckpointStepReviewers } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/CheckpointStepReviewers'
import { GoalsStepReviewers } from './GoalsStepReviewers'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Statuses } from '@src/interfaces'
import { gradeSettings } from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { FormattedMessage } from 'react-intl'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

interface Props {
  type: CheckpointType
  canAddGoals: boolean
  checkpoint?: ProbationCheckpoint | null
  requests: PerfReviewRequestFeedbackInterface[] | undefined
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
  summaryCheckpoint: ReviewSummaryInterface | undefined
  lineManager: EmployeeOptionInterface
  goalsStatus: Statuses.approved | Statuses.pending_approval | null | undefined
  onClickAddGoals: () => void
  isCreateGoalPending: boolean
  finalGrade: FinalGradeInterface | undefined
  isClosedCycle: boolean
  canApproveGoals: boolean
  canApprovePip: boolean
}

export const ProbationStepDetails = ({
  type,
  finalGrade,
  canAddGoals,
  checkpoint,
  requests,
  reviewCategory,
  cycleId,
  employeeId,
  summaryCheckpoint,
  onClickAddGoals,
  isCreateGoalPending,
  lineManager,
  goalsStatus,
  isClosedCycle,
  canApproveGoals,
  canApprovePip,
}: Props) => {
  const handleCreatePIP = () =>
    navigateTo(pathToUrl(ROUTES.FORMS.PIP.SETTINGS, { employeeId }))

  const getContent = () => {
    switch (type) {
      case CheckpointType.Goals:
        return (
          <GoalsStepReviewers
            reviewCategory={reviewCategory}
            onClickAddGoals={onClickAddGoals}
            isCreateGoalPending={isCreateGoalPending}
            canAddGoals={canAddGoals}
            canApprovePip={canApprovePip}
            goalsStatus={goalsStatus}
            lineManager={lineManager}
            approveGoalsArguments={{ employeeId, cycleId, checkpointId: checkpoint?.id }}
            canApproveGoals={canApproveGoals}
          />
        )
      case CheckpointType.Review:
        return (
          <CheckpointStepReviewers
            checkpoint={checkpoint!}
            requests={requests}
            grades={summaryCheckpoint?.summary?.final_grades}
            reviewCategory={reviewCategory}
          />
        )
      case CheckpointType.Decision:
        return (
          <CommitteeStepReviewers
            isNewDesign
            checkpoint={checkpoint!}
            reviewCategory={reviewCategory}
            cycleId={cycleId}
            employeeId={employeeId}
          />
        )
      case CheckpointType.Recommendation:
        return (
          <RecommendationStepReviewers
            checkpoint={checkpoint!}
            reviewCategory={reviewCategory}
            cycleId={cycleId}
            employeeId={employeeId}
          />
        )
      default:
        return (
          <Group width="100%" style={transparentThemeBackgroundOverrides}>
            {finalGrade ? (
              <Item py="s-8">
                <Item.Avatar>
                  <Avatar useIcon="Trophy" color={gradeSettings[finalGrade.id].color} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{finalGrade.label}</Item.Title>
                  <Item.Description>
                    <Text
                      color={Token.color.greyTone50}
                      whiteSpace="nowrap"
                      variant="body2"
                    >
                      <FormattedMessage
                        id="employee.performance.timeline.archivedGrade"
                        defaultMessage="Archived grade - {grade}"
                        values={{ grade: finalGrade.label }}
                      />
                    </Text>
                  </Item.Description>

                  {finalGrade?.id === FinalGrade.Poor && canApprovePip ? (
                    <Item.Actions>
                      <ActionButton useIcon="Wealth" onClick={handleCreatePIP}>
                        Create PIP Cycle
                      </ActionButton>
                    </Item.Actions>
                  ) : null}
                </Item.Content>
                <Item.Side>
                  <Item.Value mr={Token.space.s16}>
                    <Tag variant="outlined" color={Token.color.success} useIcon="Check">
                      Completed
                    </Tag>
                  </Item.Value>
                </Item.Side>
              </Item>
            ) : (
              <Item py="s-8">
                <Item.Avatar>
                  <Avatar useIcon="16/SandWatch" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    {isClosedCycle
                      ? 'There is no grade for this cycle'
                      : 'Grade not yet published'}
                  </Item.Title>
                </Item.Content>
              </Item>
            )}
          </Group>
        )
    }
  }

  return (
    <Flex style={{ flexGrow: 1 }} justifyContent="space-between">
      {getContent()}
    </Flex>
  )
}
