import { SkillRatingsDistributionFilterKeys } from '@src/features/ReviewCycle/Analytics/api/skills'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import { useTableReturnType } from '@components/TableV2/hooks'
import {
  SkillRatingsDistributionInterface,
  TalentSkillRatingComparableInterface,
} from '@src/features/ReviewCycle/Analytics/interfaces/skills'
import { BaseOption } from '@src/interfaces/selectors'
import { useEffect } from 'react'

interface Props {
  skills: FilterProps<FilterOption>
  teams: FilterProps<FilterOption>
  specialisations: FilterProps<FilterOption>
}

interface FilterProps<T extends BaseOption> {
  filter: FilterByInterface | undefined
  options: T[]
}

export const useTableFilters = (
  table: useTableReturnType<TalentSkillRatingComparableInterface, undefined>,
  skills: SkillRatingsDistributionInterface[],
): Props => {
  const skillsFilter = table.filterBy.find(
    ({ columnName }) => columnName === SkillRatingsDistributionFilterKeys.SkillId,
  )
  const skillsOptions: FilterOption[] = skills.map(({ skill: { id, name } }) => ({
    id,
    name,
  }))

  const teamsFilter = table.filterBy.find(
    ({ columnName }) => columnName === SkillRatingsDistributionFilterKeys.TeamId,
  )
  const teamsOptions: FilterOption[] = teamsFilter
    ? teamsFilter.filters.map(({ id, name }) => ({ id, name }))
    : []

  const specialisationsFilter = table.filterBy.find(
    ({ columnName }) =>
      columnName === SkillRatingsDistributionFilterKeys.SpecialisationId,
  )
  const specialisationsOptions: FilterOption[] = specialisationsFilter
    ? specialisationsFilter.filters.map(({ id, name }) => ({ id, name }))
    : []

  useEffect(() => {
    table.onFilterChange({
      columnName: SkillRatingsDistributionFilterKeys.SkillId,
      filters: skillsOptions,
    })
  }, [JSON.stringify(skillsOptions)])

  return {
    skills: { filter: skillsFilter, options: skillsOptions },
    teams: {
      filter: teamsFilter,
      options: teamsOptions,
    },
    specialisations: {
      filter: specialisationsFilter,
      options: specialisationsOptions,
    },
  }
}
